import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MessageToastService } from '../../service/toast/message-toast.service';
import { FormBuilder } from '@angular/forms';
import { ActivityLogService } from '../../service/activity-log/activity-log.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AuthService } from '../../service/auth-service/auth.service';

@Component({
  selector: 'app-create-activity-log',
  templateUrl: './create-activity-log.component.html',
  styleUrls: ['./create-activity-log.component.scss']
})
export class CreateActivityLogComponent implements OnInit {
  radio_selected = 'call';
  selected_tab = 0;
  form: any;
  form_reminder: any;
  reminder_checked = false;
  submit_button_text = 'SUBMIT';
  call_outcome = [
    { key: 'no_answer', value: 'No Answer' },
    { key: 'busy', value: 'Busy' },
    { key: 'wrong_number', value: 'Wrong Number' },
    { key: 'connected', value: 'Connected' }
  ];
  current_user: any;
  radio_selected_reminder = 'call';
  priority_list = [
    { key: '2', value: 'High' },
    { key: '1', value: 'Medium' },
    { key: '0', value: 'Low' }
  ];
  assignee_list = [];
  assignee: any;
  filteredOptions: Observable<string[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateActivityLogComponent>,
    private toast: MessageToastService,
    private fb: FormBuilder,
    private activityLogService: ActivityLogService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      call_outcome: '',
      lead_uuid: '',
      lead_property_uuid: '',
      logged_on: new Date(),
      note: ''
    });

    this.form_reminder = this.fb.group({
      lead_uuid: '',
      scheduled_on: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
      lead_property_uuid: '',
      assigned_user_uuid: '',
      assigned_user_type: '',
      priority: '',
      note: ''
    });
  }

  getAssignmentList() {
    this.activityLogService.getAssignmentList().subscribe(val => {
      if (val && val['status']) {
        console.log('assignment list found out');
        console.log(val);
        Object.keys(val['payload']).forEach(role => {
          val['payload'][role].forEach(assignee => {
            this.assignee_list.push({
              name: this.getLabel(assignee),
              uuid: assignee.uuid,
              type: role
            });
          });
        });

        let prefilled_assignee_label = this.getLabel(
          this.current_user.user_details
        );
        if (
          Object.keys(this.getAssignee(prefilled_assignee_label)).length != 0
        ) {
          this.form_reminder
            .get('assigned_user_uuid')
            .setValue(prefilled_assignee_label);
        }

        this.filteredOptions = this.form_reminder
          .get('assigned_user_uuid')
          .valueChanges.pipe(
            startWith(''),
            map(value => this._filter(<string>value))
          );
      }
    });
  }

  getLabel(assignee) {
    let label = assignee.name + ' - ';
    if (assignee.email && assignee.email != '') {
      label += assignee.email;
    } else if (assignee.phone_number && assignee.phone_number != '') {
      label += assignee.phone_number;
    }

    return label;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.assignee_list.filter(option =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  radioChange(event) {
    this.radio_selected = event.value;
  }

  radioChangeReminder(event) {
    this.radio_selected_reminder = event.value;
  }

  setReminder() {
    if (this.form.get('set_reminder').value) {
      this.submit_button_text = 'SUBMIT AND SET REMINDER';
    } else {
      this.submit_button_text = 'SUBMIT';
    }
  }

  submit() {
    if (this.checkValid()) {
      if (this.selected_tab == 0 || this.selected_tab == 2) {
        let formData = JSON.parse(JSON.stringify(this.form.value));
        formData['log_type'] =
          this.selected_tab == 2 ? 'log' : this.radio_selected;
        formData['logged_on'] = Math.floor(
          this.form.get('logged_on').value.getTime() / 1000
        );
        if (
          (this.radio_selected != 'call' && this.selected_tab == 0) ||
          this.selected_tab == 2
        ) {
          delete formData['call_outcome'];
        }
        formData['lead_uuid'] = this.data.lead.uuid;
        formData['lead_property_uuid'] = this.data.uuid;
        console.log('sending packet');
        console.log(formData);
        this.activityLogService.createActivityLog(formData).subscribe(val => {
          if (val && val['status']) {
            this.toast.success('SUCCESSFULLY CREATED ACTIVITY LOG');
            if(this.reminder_checked && this.selected_tab == 0) {
              this.selected_tab = 1;
              this.reminder_checked = false;
              this.submit_button_text = 'SUBMIT';
            } else {
              this.dialogRef.close();
            }
          } else {
            this.toast.error('OPERATION FAILED');
          }
        });
      } else if (this.selected_tab == 1) {
        let formData = JSON.parse(JSON.stringify(this.form_reminder.value));

      formData['log_type'] = this.radio_selected_reminder;
      formData['scheduled_on'] = Math.floor(
        this.form_reminder.get('scheduled_on').value.getTime() / 1000
      );
      formData['lead_uuid'] = this.data.lead.uuid;
      formData['lead_property_uuid'] = this.data.uuid;

      let assignee = this.getAssignee(formData['assigned_user_uuid']);

      if (Object.keys(assignee).length != 0) {
        formData['assigned_user_uuid'] = assignee['uuid'];
        formData['assigned_user_type'] = assignee['type'];
        if (!(formData['priority'] && formData['priority'] != '')) {
          delete formData['priority'];
        }
        console.log('sending packet');
        console.log(formData);
        this.activityLogService.setReminder(formData).subscribe(val => {
          if (val && val['status']) {
            this.toast.success('REMINDER CREATED SUCCESSFULLY');
            this.dialogRef.close();
          } else {
            this.toast.error('OPERATION FAILED');
          }
        });
      } else {
        this.toast.error('PLEASE SELECT ASSIGNEE FROM DROPDOWN');
      }
      }
    } else {
      this.toast.error('OPERATION FAILED');
    }
  }

  checkValid() {
    let noteField = this.form.get('note');
    let callOutcomeField = this.form.get('call_outcome');
    if (this.selected_tab == 0) {
      if (
        noteField.value &&
        noteField.value != '' &&
        ((this.radio_selected == 'call' &&
          callOutcomeField.value &&
          callOutcomeField.value != '') ||
          this.radio_selected != 'call')
      ) {
        return true;
      } else {
        return false;
      }
    } else if(this.selected_tab == 1) {
      let reminderNoteField = this.form_reminder.get('note');
      let dateField = this.form_reminder.get('scheduled_on');
    let priorityField = this.form_reminder.get('priority');
    let userField = this.form_reminder.get('assigned_user_uuid');
    if (
      reminderNoteField.value &&
      reminderNoteField.value != '' &&
      dateField.value &&
      dateField.value != '' &&
      priorityField.value &&
      priorityField.value != '' &&
      userField.value &&
      userField.value != ''
    ) {
      return true;
    }
    return false;
    }
    else if (this.selected_tab == 2) {
      if (noteField.value && noteField.value != '') {
        return true;
      } else {
        return false;
      }
    }
  }

  getAssignee(name) {
    let match_found = null;
    this.assignee_list.forEach(assignee => {
      if (assignee.name == name && !match_found) {
        match_found = assignee;
      }
    });
    if (match_found) {
      return match_found;
    } else {
      return {};
    }
  }

  onTabChange(event) {
    console.log('tab changed');
    console.log(event);
    this.selected_tab = event.index;
    if(this.selected_tab == 1) {
      this.resetReminderForm();
      this.getAssignmentList();
      this.current_user = JSON.parse(this.authService.getUser());
    } else if(this.selected_tab == 0 || this.selected_tab == 2) {
      this.resetForm();
    }
  }

  resetReminderForm() {
    this.form_reminder.get('lead_uuid').setValue('');
    this.form_reminder.get('scheduled_on').setValue(new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000));
    this.form_reminder.get('lead_property_uuid').setValue('');
    this.form_reminder.get('assigned_user_uuid').setValue('');
    this.form_reminder.get('assigned_user_type').setValue('');
    this.form_reminder.get('priority').setValue('');
    this.radio_selected_reminder = 'call';
    this.form_reminder.get('note').setValue('');
  }

  resetForm() {
    this.form.get('note').setValue('');
    this.form.get('call_outcome').setValue('');
    this.form.get('lead_uuid').setValue('');
    this.form.get('lead_property_uuid').setValue('');
    this.form.get('logged_on').setValue(new Date());
    this.radio_selected = 'call';
    this.reminder_checked = false;
  }

  reminderCheck(event) {
    console.log('checkbox selected');
    this.reminder_checked = event.target.checked;
    if(this.reminder_checked) {
      this.submit_button_text = 'SUBMIT AND SET REMINDER';
    }
    else {
      this.submit_button_text = 'SUBMIT';
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
