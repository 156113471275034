import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {
  @Output() option: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<DeleteModalComponent>,
  ) { }

  ngOnInit() {
  }

  option_yes() {
    this.option.emit('success');
    this.dialogRef.close();
  }

  option_no() {
    this.dialogRef.close();
  }

}
