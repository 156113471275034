import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { Validators, FormBuilder } from "@angular/forms";
import { ReportingService } from "src/app/common/service/reporting/reporting.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
import { Subscription } from "rxjs";
import { DatePipe } from "@angular/common";
import { MenuToggleService } from "src/app/common/service/menu-toggle/menu-toggle.service";
declare var $: any;

@Component({
  selector: "app-lead-report",
  templateUrl: "./lead-report.component.html",
  styleUrls: ["./lead-report.component.scss"]
})
export class LeadReportComponent implements OnInit {
  dataFreq = "Daily Data";
  form: any;
  // freqOptions = ["Daily", "Monthly", "Yearly"];
  value = "leads";
  leadChartData = [];
  propChartData = [];
  leadView: any = [2000, 400];
  propView: any = [2000, 400];
  selectedCluster = null;
  subscription = new Subscription();
  leadDataFlag = false;
  propDataFlag = false;
  globalSource = [];

  //chart Options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  tooltipDisabled = false;
  xAxisLabel = "Days";
  showYAxisLabel = true;
  yAxisLabel_leads = "Number of Leads";
  yAxisLablel_props = "Number of Properties";
  showGridLines = true;
  innerPadding = 0;
  barPadding = 2;
  groupPadding = 16;
  roundDomains = false;
  maxRadius = 10;
  minRadius = 3;

  // dummy data for testing
  multi = [];

  // graph color scheme
  // colors = ["#5c6bc0", "#66bb6a", "#29b6f6", "#ffee58", "#ef5350", "#868e96"];
  colors = [
    "#90caf9",
    "#1a4e6b",
    "#f4ab33",
    "#c068a8",
    "#ec7176",
    "#bb4e4b",
    "#43A047",
    "#795548",
    '#BD9FA0',
    "#FAF0EB",
    "#EBB9BB",
    "#BAB9BD",
    "#73FFC9",
    "#739EFF"
  ];
  colorScheme = {
    domain: this.colors
  };
  schemeType = "ordinal";

  @ViewChild("sidenav", { static: true }) sidenavbar: MatSidenav;
  constructor(
    private fb: FormBuilder,
    private reportingService: ReportingService,
    private toast: MessageToastService,
    private clusterService: ClusterService,
    private datepipe: DatePipe,
    private menuToggle: MenuToggleService
  ) {}

  ngOnInit() {
    this.leadView = null;
    this.propView = this.leadView;
    this.form = this.fb.group({
      from_date: [
        new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
        Validators.required
      ],
      to_date: [new Date(), Validators.required]
    });

    this.subscription.add(
      this.clusterService.globalCluster.subscribe(val => {
        if (val) {
          this.selectedCluster = val;
          $(".funnel-filter-container").click();
          this.submit();
        }
      })
    );
    // this.form = this.fb.group({
    //   freq: ["Daily", Validators.required],
    //   day: [new Date()],
    //   month: [new Date().getMonth()],
    //   year: [new Date().getFullYear()]
    // });
    this.menuToggle.menuToggle.subscribe(val => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, (100));
    });
  }

  sidenavToggle() {
    this.sidenavbar.toggle();
  }

  async submit() {
    console.log("submitted data");
    let data = [];
    data["from_date"] = Math.floor(
      this.form.get("from_date").value.getTime() / 1000
    );
    data["to_date"] = Math.floor(
      this.form.get("to_date").value.getTime() / 1000
    );
    data["cluster_id"] = this.selectedCluster.uuid;
    console.log(data);

    let sources = await this.reportingService.getGlobalSource(data).toPromise();
    this.globalSource = [];
    this.globalSource.push(...sources["payload"].source_list);

    console.log("global source right now");
    console.log(this.globalSource);

    // global lead report
    this.reportingService.getGlobalLeadReport(data).subscribe(val => {
      if (val && val["status"]) {
        this.toast.success("LEADS DATA FETCHED SUCCESSFULLY");
        this.getChartData(val, "leads");
        this.leadDataFlag = true;
      } else {
        this.toast.error(val["message"].toUpperCase());
        this.leadDataFlag = false;
      }
    });

    // global property report
    this.reportingService.getGlobalPropertyReport(data).subscribe(val => {
      if (val && val["status"]) {
        this.toast.success("LEAD DATA FETCHED SUCCESSFULLY");
        this.getChartData(val, "properties");
        this.propDataFlag = true;
      } else {
        this.toast.error("NO DATA");
        this.propDataFlag = false;
      }
    });

    // console.log("submit button clicked");
    // let frequency = this.form.get('freq').value;
    // if( frequency == 'Daily') {
    //   this.dataFreq = 'Daily Data';
    //   console.log('Daily');
    //   console.log(this.form.get('day').value);
    // }
    // else if(frequency == 'Monthly') {
    //   this.dataFreq = 'Monthly Data';
    //   console.log('Monthly');
    //   console.log(this.form.get('month').value._d);
    // }
    // else if(frequency == 'Yearly') {
    //   this.dataFreq = 'Yearly Data';
    //   console.log('Yearly');
    //   console.log(this.form.get('year').value._d);
    // }

    this.sidenavbar.close();
  }

  // radioChange(event) {
  //   this.value = event.value;
  //   this.submit();
  // }

  getChartData(val, source) {
    let columns = Object.keys(val["payload"]).length;
    let width = columns < 10 ? 80 * columns : 10 * columns + 90;

    if (source == "leads") {
      this.leadChartData = this.correctData(val["payload"]);
      console.log("corrected data");
      console.log(this.leadChartData);
      this.leadView = null;
      // this.leadView = [width, 30 * Math.round(window.innerHeight / 100)];
    } else {
      this.propChartData = this.correctData(val["payload"]);
      this.propView = null;
      // this.propView = [width, 30 * Math.round(window.innerHeight / 100)];
    }
  }

  correctData(data): any {
    console.log("data we are correcting");
    console.log(data);
    let graphData: any = [];
    Object.keys(data).forEach(key => {
      if (key != "total_count") {
        let obj = {};
        obj["name"] = key;
        obj["series"] = [];
        this.globalSource.forEach(source => {
          if (data[key][source]) {
            obj["series"].push({ name: source, value: data[key][source] });
          } else {
            obj["series"].push({ name: source, value: 0 });
          }
        });
        // Object.keys(data[key]).forEach(ele => {
        //   obj["series"].push({ name: ele, value: data[key][ele] });
        // });
        graphData.push(obj);
      }
    });
    return graphData;
  }

  getTooltipTime(val) {
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    let date = new Date(val * 1000);
    let modDate =
      ("0" + date.getDate()).slice(-2) +
      " " +
      monthNames[date.getMonth()] +
      " " +
      ("0" + (date.getFullYear())).slice(-2);
    // let dateObject = date.getFullYear() +'/'+ ('0' + (date.getMonth() + 1)).slice(-2) +'/'+ ('0' + date.getDate()).slice(-2);
    return modDate.toString();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
