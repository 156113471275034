import { Component, OnInit } from "@angular/core";
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
import { AccountService } from "src/app/common/service/account/account.service";
import { StageService } from "src/app/common/service/stage/stage.service";
import { MatDialog } from "@angular/material/dialog";
import { DeleteModalComponent } from "src/app/common/component/delete-modal/delete-modal.component";

@Component({
  selector: "app-create-stage",
  templateUrl: "./create-stage.component.html",
  styleUrls: ["./create-stage.component.scss"]
})
export class CreateStageComponent implements OnInit {
  rows = [];
  count = 0;
  offset = 0;
  limit = 10;
  clusters = [];
  selectedCities1 = [];
  isEditMode = false;
  isFilterCollapsed = false;
  isCollapsed = false;
  clusterList = [];
  filterApplied = false;
  partnerList = [];
  partnerListDetails = [];
  form: FormGroup;
  filterForm: FormGroup;
  formValid: boolean = false;
  formValid1: boolean = false;

  displayColor = [
    {
      label: "Select Display Color",
      value: ""
    },
    {
      label: "Black",
      value: "#000000"
    },
    {
      label: "Maroon",
      value: "#800000"
    },
    {
      label: "Olive",
      value: "#808000"
    },
    {
      label: "Navy",
      value: "#000080"
    },
    {
      label: "Green",
      value: "#008000"
    },
    {
      label: "Purple",
      value: "#800080"
    },
    {
      label: "Red",
      value: "#ff0000"
    },
    {
      label: "Yellow",
      value: "#ffff00"
    },
    {
      label: "Blue",
      value: "#0000ff"
    },
    {
      label: "White",
      value: "#ffffff"
    },
    {
      label: "DarkRed",
      value: "#5f0000"
    },
    {
      label: "DarkSeaGreen",
      value: "#87af87"
    },
    {
      label: "Sky Blue",
      value: "#87afff"
    }
  ];
  accessList = [
    {
      label: "NA",
      value: "na"
    },
    {
      label: "View",
      value: "view"
    },
    {
      label: "Edit",
      value: "edit"
    }
  ];

  stageGroup = [
    {
      label: "Select Stage Group",
      value: ""
    },
    {
      label: "Acquisition",
      value: "acquisition"
    },
    {
      label: "Feasibility",
      value: "feasibility"
    },
    {
      label: "Execution",
      value: "execution"
    },
    {
      label: "QC",
      value: "qc"
    },
    {
      label: "Collection",
      value: "collection"
    }
  ];

  constructor(
    private fb: FormBuilder,
    private stageService: StageService,
    private clusterService: ClusterService,
    private toast: MessageToastService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.filterForm = this.fb.group({
      cluster_id: ["", Validators.required]
    });

    this.form = this.fb.group({
      uuid: [null],
      display_name: [
        null,
        Validators.compose([Validators.maxLength(100), Validators.required])
      ],
      display_color: [
        "",
        Validators.compose([Validators.maxLength(100), Validators.required])
      ],
      timeline_days: [
        '5',
        Validators.compose([Validators.maxLength(3), Validators.required])
      ],
      customer_display_name: [
        "",
        Validators.compose([Validators.maxLength(50), Validators.required])
      ],
      is_default: [false],
      stage_group: [
        "",
        Validators.compose([Validators.maxLength(100), Validators.required])
      ],
      access_clusteradmin: [
        "view",
        Validators.compose([Validators.maxLength(20), Validators.required])
      ],
      access_amplus_business: [
        "view",
        Validators.compose([Validators.maxLength(20), Validators.required])
      ],
      access_partner_epc: [
        "view",
        Validators.compose([Validators.maxLength(20), Validators.required])
      ],
      access_partner_sales: [
        "view",
        Validators.compose([Validators.maxLength(20), Validators.required])
      ],
      access_amplus_admin: [
        "view",
        Validators.compose([Validators.maxLength(20), Validators.required])
      ],
      access_amplus_finance: [
        "view",
        Validators.compose([Validators.maxLength(20), Validators.required])
      ],
      access_amplus_analytics: [
        "view",
        Validators.compose([Validators.maxLength(20), Validators.required])
      ],
      access_amplus_technical: [
        "view",
        Validators.compose([Validators.maxLength(20), Validators.required])
      ],
      deadline_mail_format:[
        "Hi,<br>The current stage {stage_name} is pending, please submit this stage for further process.<br>Regards",
        Validators.compose([Validators.required])
      ],
      dealine_mail:[
        "",
        Validators.compose([Validators.required])
      ],

    });
    this.clusters.push({
      label: "Select a cluster",
      value: null
    });

    this.filterForm.valueChanges.subscribe(() => {
      this.formValid = this.filterForm.valid;
    });

    this.form.valueChanges.subscribe(() => {
      this.formValid1 = this.form.valid;
    });

    this.getClusters();
  }

  submit() {
    if (this.form.valid && this.filterForm.valid) {
      let data = this.form.value;
      data["cluster_id"] = this.filterForm.get("cluster_id").value;
      if (this.isEditMode) {
        this.stageService.updateStageData(data).subscribe((result: any) => {
          if (result && result.status) {
            this.reset();
            this.toast.success("STAGE UPDATED SUCCESSFULLY");
            this.getStagesList();
          }
          else if(result && !result.status){
            this.toast.error('ERROR '+result.message);
          }
        });
      } else {
        this.stageService.saveStageData(data).subscribe((result: any) => {
          if (result && result.status) {
            this.reset();
            this.getStagesList();
            this.toast.success("STAGE CREATED SUCCESSFULLY");
          }
          else if(result && !result.status){
            this.toast.error('ERROR '+ result["message"].toUpperCase());
          }
        });
      }
    }
  }

  getStagesList() {
    this.filterApplied = true;

    let queryParams = "uuid=" + this.filterForm.get("cluster_id").value;
    this.getStageDetails(queryParams);
  }

  editAccount(row) {}

  deleteAccount(row) {
    let dialogRef = this.dialog.open(DeleteModalComponent);

    dialogRef.componentInstance.option.subscribe(data => {
      if(data == 'success') {
        this.stageService.deleteStageData(row.uuid).subscribe((result: any) => {
          if (result && result.status) {
            this.reset();
            this.toast.success("STAGE DELETED SUCCESSFULLY");
            this.getStagesList();
          }
        });
      }});
  }

  reset() {
    this.form.reset();
    this.form.patchValue({
      is_default: false,
      access_clusteradmin: "view",
      access_amplus_business: "view",
      access_partner_epc: "view",
      access_partner_sales: "view",
      access_amplus_admin: "view",
      access_amplus_finance: "view",
      access_amplus_analytics: "view",
      access_amplus_technical: "view"
    });
    this.form.get("display_color").setValue("");
    this.isEditMode = false;
  }

  getStageDetails(queryParam) {
    this.stageService.getStageList(queryParam).subscribe((result: any) => {
      if (result && result.status) {
        this.partnerList = result.payload.stages;
        console.log('parnterlist found out');
        console.log(this.partnerList);
        console.log(result);
        this.partnerListDetails = result.payload.stages;
        this.offset = 0;
      }
    });
  }

  getStageData(data) {
    let queryParams = "uuid=" + data.uuid;
    this.stageService.getStageData(queryParams).subscribe((result: any) => {
      if (result && result.status) {
        let row = result.payload.stage;
        this.form.patchValue({
          uuid: row.uuid,
          display_name: row.display_name,
          display_color: row.display_color,
          customer_display_name: row.customer_display_name,
          stage_group: row.stage_group,
          access_partner_epc: row.access_partner_epc,
          access_partner_sales: row.access_partner_sales,
          access_clusteradmin: row.access_clusteradmin,
          access_amplus_business: row.access_amplus_business,
          access_amplus_admin: row.access_amplus_admin,
          access_amplus_finance: row.access_amplus_finance,
          access_amplus_analytics: row.access_amplus_analytics,
          access_amplus_technical: row.access_amplus_technical,
          is_default: row.is_default,
          timeline_days: row.timeline_days,
            deadline_mail_format : row.deadline_mail_format,
          dealine_mail:row.dealine_mail
        });
        this.filterForm.patchValue({
          cluster_id: row.cluster.uuid
        });
        this.getStagesList();
        this.isEditMode = true;
      }
    });
  }

  getClusters() {
    this.clusterService.getClusterList().subscribe(res => {
      res["payload"].clusters.forEach(element => {
        this.clusters.push({
          label: element.identifier,
          value: element.uuid
        });
      });
    });
  }

  onPage(event) {
    // console.log('printing create stage log');
    // console.log(event);
    // console.log('printing partner list');
    // console.log(this.partnerListDetails);
    // this.offset = event.offset;
    // const start = this.offset * this.limit;
    // const end = start + this.limit;
    // const rows = [];
    // let index = 0;
    // for (let i = start; i < end; i++) {
    //   rows[index] = this.partnerListDetails[i];
    //   index++;
    // }
    // console.log(rows);

    // this.partnerList = rows;
  }
}
