import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
import * as FunnelGraph from "funnel-graph-js";
import { MatSidenav } from "@angular/material/sidenav";
import { ReportingService } from "src/app/common/service/reporting/reporting.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { AuthService } from "src/app/common/service/auth-service/auth.service";
import { Subscription, BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { googleMapStyle } from "src/resource/setting-data";
declare var $: any;
declare var google: any;

@Component({
  selector: "app-lead-performance",
  templateUrl: "./lead-performance.component.html",
  styleUrls: ["./lead-performance.component.scss"]
})
export class LeadPerformanceComponent implements OnInit {
  isFilterCollapsed = false;
  filterForm: any;
  form: any;
  clusters = [];
  graph: any;
  stageGraph: any;
  totalLeads = 0;
  stageLeads = 0;
  salesPartnerList: any;
  epcPartnerList: any;
  clusterSelected: boolean = false;
  stageSelected: boolean = false;
  selectedStageName = "";
  salesCheck: boolean = true;
  epcCheck: boolean = true;
  params: any;
  _1vh: number;
  filterValidCheck: boolean = true;
  showButton: boolean = true;
  filterText: string = "Select a cluster";
  @ViewChild("sidenav", { static: true }) sidenavBar: MatSidenav;
  funnelGraph: FunnelGraph;
  responseData: any;
  stageLabels: any;
  stageValues: any;
  markerList = [];
  listenerList = [];
  map: any;
  position: any;
  activeIcon = "../../../assets/images/location_marker_active.png";
  inactiveIcon = "../../../assets/images/location_marker_inactive.png";
  bounds: any;
  geoPointCount = 0;
  regionList = [{ label: "Please select a region", value: "" },
  { label: "North", value: "North" }, { label: "East", value: "East" }, { label: "West", value: "West" }, { label: "South", value: "South" }];
  clusterLoaded = new BehaviorSubject(null);
  private subscription: Subscription = new Subscription();

  funnelValues: any = [1, 1];
  funnelLables: any = ["Select a Cluster", " "];

  constructor(
    private fb: FormBuilder,
    private clusterService: ClusterService,
    private reportingService: ReportingService,
    private toast: MessageToastService,
    private authService: AuthService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    let user = JSON.parse(this.authService.getUser());
    this._1vh = Math.round(window.innerHeight / 100);
    console.log("value of vh calculated");
    console.log(this._1vh);

    this.filterForm = this.fb.group({
      cluster_id: ["", Validators.required]
    });

    this.form = this.fb.group({
      sales_agent_id: ["", Validators.required],
      epc_agent_id: ["", Validators.required],
      from_date: ["", Validators.required],
      to_date: ["", Validators.required],
      region: [""]
    });

    this.form.get("region").setValue("");

    this.subscription.add(
      this.clusterService.globalCluster.subscribe(val => {
        if (val) {
          console.log("value of global cluster in lead performance");
          console.log(val);
          this.filterForm.get("cluster_id").setValue(val.uuid);
          this.getLeadReport();
          this.clusterLoaded.next(true);
        }
      })
    );

    const funnelOptions = {
      chart: {
        type: "funnel"
      },
      title: {
        text: "Stage funnel"
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b> ({point.y:,.0f})",
            softConnector: true
          },
          center: ["40%", "50%"],
          neckWidth: "30%",
          neckHeight: "25%",
          width: "80%"
        }
      },
      legend: {
        enabled: false
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              plotOptions: {
                series: {
                  dataLabels: {
                    inside: true
                  },
                  center: ["50%", "50%"],
                  width: "100%"
                }
              }
            }
          }
        ]
      }
    };

    if (user["user_type"] == "cluster_admin") {
      this.filterText = "Selected Cluster";
      this.clusters.push({
        value: user.cluster_details.uuid,
        label: user.cluster_details.identifier
      });
      this.filterForm.get("cluster_id").setValue(user.cluster_details.uuid);
      this.showButton = false;
      this.clusterSelected = true;
      this.stageSelected = false;
      this.getLeadReport();
      this.filterForm.disable();
    } else {
      this.clusters.push({
        label: "Select a cluster",
        value: null
      });
      this.getClusters();
    }
  }

  ngAfterViewInit() {
    this.clusterLoaded.subscribe(val => {
      if (val) {
        this.getMapDetails();
      }
    });
  }

  getMapDetails(params?) {
    // if(this.markerList.length > 0) {
    //   this.markerList.forEach((marker) => { marker.remove(); });
    // }

    // if(this.listenerList.length > 0) {
    //   this.listenerList.forEach((listener) => { google.maps.event.removeListener(listener); })
    // }

    // let params = { cluster_id: this.filterForm.get("cluster_id").value };
    // if (this.form.get("region").value != null && this.form.get("region").value != "") {
    //   params['region'] = this.form.get("region").value;
    // }

    if(params == null) {
      params = { cluster_id: this.filterForm.get("cluster_id").value };
    }

    this.reportingService
      .getLeadMap(params)
      .subscribe(val => {
        this.geoPointCount = val["payload"].length;
        console.log("data for creating map");
        console.log(val["payload"]);
        setTimeout(() => {
          this.createMap(val["payload"]);
        }, 500);
        google.maps.event.trigger(this.map, "resize");
        // this.map.setZoom(15);
      }); // without delay map doesn't fits the container
  }

  createMap(property_details) {
    setTimeout(() => {
      this.bounds = new google.maps.LatLngBounds();
      this.position = new google.maps.LatLng(28.41972, 77.10529);
      this.map = new google.maps.Map(document.getElementById("lead-map"), {
        center: this.position,
        zoom: 2,
        clickableIcons: false,
        styles: googleMapStyle,
        mapTypeControl: false
      });

      console.log("property details found out");
      console.log(property_details);

      property_details.forEach(property => {
        let locData = JSON.parse(property.location).geocode;
        let location = new google.maps.LatLng(locData[0], locData[1]);
        let mapicon = {
          url:
            property.status == "active" ? this.activeIcon : this.inactiveIcon, // url
          scaledSize: new google.maps.Size(30, 30), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(0, 0) // anchor
        };
        let property_marker = new google.maps.Marker({
          position: location,
          map: this.map,
          icon: mapicon,
          map_icon_label:
            '<span class="map-icon map-icon-point-of-interest"></span>'
        });

        this.markerList.push(property_marker);

        let contentString =
          " <style>" +
          "table { font-family: arial, sans-serif; border-collapse: collapse; width: 100%; }" +
          "td, th { border: 1px solid #dddddd; text-align: left; padding: 8px; }" +
          "tr:nth-child(even) { background-color: #dddddd; }" +
          "</style>" +
          "</head>" +
          "<body class='marker-infowindow'>" +
          '<h4 class="info-heading">Lead Details</h4>' +
          "<table>" +
          "<tr>" +
          "<th>Lead Identifier</th>" +
          "<th>" +
          property.identifier +
          "</th>" +
          "</tr>" +
          "<tr>" +
          "<td>Lead Status</td>" +
          "<td>" +
          property.status.toUpperCase() +
          "</td>" +
          "</tr>" +
          "<tr>" +
          "<td>Location</td>" +
          "<td>" +
          JSON.parse(property.location).title.split(",", 1) +
          "</td>" +
          "</tr>" +
          "</table>";
        let infowindow = new google.maps.InfoWindow({
          content: contentString
        });
        var listenerHandle = property_marker.addListener("click", function () {
          console.log("click detected");
          infowindow.open(this.map, property_marker);
        });

        this.listenerList.push(listenerHandle);

        this.map.fitBounds(this.bounds.extend(location));
        console.log("current zoom level");
        console.log(this.map.getZoom());
      });
    }, 200);
    // if (this.map.getZoom() > 2) {
    //   console.log("setting the zoom level");
    //   this.map.setZoom(2);
    // }
  }

  createGraph() {
    $("#container").empty();

    this.graph = new FunnelGraph({
      container: ".funnel-container",
      gradientDirection: "horizontal",
      data: {
        labels: [...this.funnelLables],
        colors: ["#ffaf78", "#ff9180", "#ff4a8d"],
        values: [...this.funnelValues]
      },
      displayPercent: true,
      direction: "horizontal",
      height: this._1vh * 12,
      width: this.findWidth(this.funnelLables.length) / 1.25
    });

    this.graph.draw();
    this.correctGraphData();

    setTimeout(() => {
      let funnelDiv = document.getElementsByClassName("svg-funnel-js__labels");
      for (var i = 0; i < funnelDiv.length; i++) {
        funnelDiv[i].addEventListener("click", event => {
          console.log("funnel div clicked");
          let division = (<HTMLInputElement>(
            (<HTMLInputElement>event["srcElement"]).childNodes[1]
          )).innerText;
          this.stageSelected = true;
          setTimeout(() => {
            this.createStageGraph(division);
          }, 100);
        });
        if (i == 0) {
          // var clickEvent = new Event("click");
          // funnelDiv[i].dispatchEvent(clickEvent);
          let division = funnelDiv[i].childNodes[0].childNodes[1].textContent;
          if (division) {
            this.stageSelected = true;
            setTimeout(() => {
              this.createStageGraph(division);
            }, 100);
          }
        }
      }
    }, 100);

    // this.form.valueChanges.subscribe(res => {
    //   if((this.form.get('sales_agent_id').valid && this.form.get('sales_agent_id').value != '' && this.form.get('sales_agent_id').value != null) ||
    //   (this.form.get('epc_agent_id') && this.form.get('epc_agent_id').value != '' && this.form.get('sales_agent_id').value != null) ||
    //   (this.form.get('from_date') && this.form.get('from_date').value != null) ||
    //   (this.form.get('to_date') && this.form.get('to_date').value != null)) {
    //     this.filterValidCheck = true;
    //   }
    //   else {
    //     this.filterValidCheck = false;
    //   }
    // });
  }

  findWidth(len): number {
    // if (len < 6) {
    //   return 1200;
    // } else {
    //   return len * 200;
    // }
    return len * 200;
  }

  correctGraphData() {
    console.log("displaying all the elements");
    console.log(document.getElementsByClassName("label__percentage"));

    Array.from(document.getElementsByClassName("label__percentage")).forEach(
      function (item) {
        console.log(item.innerHTML);
        if (item.innerHTML == "NaN%") item.innerHTML = "0%";
      }
    );
  }

  createStageGraph(division) {
    division = "LEAD PROCESSING";
    console.log("creating graph for " + this.responseData);
    this.selectedStageName = division;
    let stageValue = [];

    for (
      var i = 0;
      i < this.responseData["payload"].sub_stages_count.length;
      i++
    ) {
      if (
        this.responseData["payload"].sub_stages_count[
          i
        ].display_name.toUpperCase() == division
      ) {
        stageValue = this.responseData["payload"].sub_stages_count[i]
          .status_count;
        break;
      }
    }

    this.stageLabels = [];
    this.stageValues = [];
    this.stageLeads = 0;
    console.log(stageValue);
    Object.keys(stageValue).forEach(val => {
      this.stageLabels.push(val.toUpperCase());
      this.stageValues.push(stageValue[val]);
      this.stageLeads += stageValue[val];
    });

    this.drawStageGraph();
  }

  drawStageGraph() {
    $("#stage-container").empty();

    this.stageGraph = new FunnelGraph({
      container: ".stage-funnel-container",
      gradientDirection: "horizontal",
      data: {
        labels: [...this.stageLabels],
        colors: ["#ffaf78", "#ff9180", "#ff4a8d"],
        values: [...this.stageValues]
      },
      displayPercent: true,
      direction: "horizontal",
      height: this._1vh * 12,
      width: this.findWidth(this.stageLabels.length) / 1.24
    });

    this.stageGraph.draw();
    this.correctGraphData();

    let element = document.getElementById("mat-sidenav-funnel-container");
    element.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest"
    });
    // element.scrollHeight;
  }

  cleanData(data) {
    this.funnelLables = [];
    this.funnelValues = [];
    console.log("the data payload feading in funnel");
    console.log(data.payload.stage_group_list);
    data.payload.sub_stages_count.forEach(val => {
      this.funnelValues.push(val.lead_count);
      this.funnelLables.push(val.display_name.toUpperCase());
    });

    console.log("cleaned data");
    console.log(this.funnelValues);
    console.log(this.funnelLables);
    this.totalLeads = data.payload.total_lead_count;
  }

  getClusters() {
    this.clusterService.getClusterList().subscribe(res => {
      res["payload"].clusters.forEach(element => {
        this.clusters.push({
          label: element.identifier,
          value: element.uuid
        });
      });
    });
  }

  sidenavToggle() {
    // if (!this.clusterSelected) {
    //   this.toast.error("PLEASE SELECT A CLUSTER");
    // } else {
    this.sidenavBar.toggle();
    // this.form.reset();
    // this.form.patchValue({
    //   sales_agent_id: "",
    //   epc_agent_id: ""
    // });
    // }
  }

  getLeadReport() {
    this.params = { cluster_id: this.filterForm.get("cluster_id").value };
    console.log("sending params");
    console.log(this.params);
    this.getFilterData(this.params);
    this.getChartData(this.params);
    this.clearFilter();
    $(".filter-header").click();
  }

  getFilterData(params) {
    this.reportingService.getLeadReportList(params).subscribe(res => {
      if (res && res["status"]) {
        console.log("value from API call");
        console.log(res);
        this.clusterSelected = true;
        this.stageSelected = false;
        this.salesPartnerList = [];
        this.salesPartnerList.push({
          uuid: "",
          name: "Please select a sales agent",
          number: ""
        });
        this.salesPartnerList.push(...res["payload"].sales_partners);
        if (res["payload"].sales_partners.length == 0) {
          this.salesCheck = false;
        } else {
          this.salesCheck = true;
        }

        this.epcPartnerList = [];
        this.epcPartnerList.push({
          uuid: "",
          name: "Please select an epc agent",
          number: ""
        });
        this.epcPartnerList.push(...res["payload"].epc_partners);

        if (res["payload"].epc_partners.length == 0) {
          this.epcCheck = false;
        } else {
          this.epcCheck = true;
        }
      } else {
        this.toast.error("OPERATION FAILED");
        this.salesCheck = false;
        this.epcCheck = false;
        this.filterForm.patchValue({
          cluster_id: ""
        });
      }
    });
  }

  getChartData(params) {
    this.reportingService.getLeadReport(params).subscribe(res => {
      if (res && res["status"]) {
        this.toast.success("DATA FETCHED SUCCESSFULLY");
        console.log("info from chart data");
        console.log(res);
        this.responseData = res;
        this.sidenavBar.close();
        this.cleanData(res);
      } else {
        this.toast.error("OPERATION FAILED - NO DATA");
        this.funnelValues = [0];
        this.funnelLables = ["NO DATA"];
        this.totalLeads = 0;
      }
      setTimeout(() => {
        this.createGraph();
      }, 200);
    });
  }

  formatOption(data) {
    let option_text: string;
    if (data.number) {
      option_text = data.name + " (" + <string>data.number + ")";
    } else {
      option_text = data.name;
    }
    return option_text;
  }

  filter() {
    console.log("printing filter form value");
    console.log(this.form.value);
    let formData = this.form.value;
    let cleanedData = [];

    if (
      formData["sales_agent_id"] != "" &&
      formData["sales_agent_id"] != null
    ) {
      cleanedData["sales_agent_id"] = formData["sales_agent_id"];
    }

    if (formData["epc_agent_id"] != "" && formData["epc_agent_id"] != null) {
      cleanedData["epc_agent_id"] = formData["epc_agent_id"];
    }

    if (formData["from_date"] != null && formData["from_date"] != "") {
      cleanedData["from_date"] = Math.floor(
        formData["from_date"].getTime() / 1000
      );
    }

    if (formData["to_date"] != null && formData["to_date"] != "") {
      cleanedData["to_date"] = Math.floor(formData["to_date"].getTime() / 1000);
    }

    if (formData["region"] != null && formData["region"] != "") {
      cleanedData["region"] = formData["region"];
    }

    console.log("printing cleaned data");
    console.log(cleanedData);
    cleanedData["cluster_id"] = this.params["cluster_id"];
    this.getChartData(cleanedData);
    this.getMapDetails(cleanedData);
  }

  clearFilter() {
    this.form.reset();
    this.form.patchValue({
      sales_agent_id: "",
      epc_agent_id: "",
      region: ""
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
