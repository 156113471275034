import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BaseService } from "../base.service";
import { MessageToastService } from "../toast/message-toast.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ClusterService {
  clusterURL = "v1/cluster/info?";
  junkClusterListURL = "v1/property/cluster/list";
  getClusterListURL = "v1/cluster/list";
  assignClusterURL = "v1/property/unassigned/cluster";
  clusterAdminURL = "v1/account/clusteradmin?";
  clusterAdminList = "v1/account/clusteradmin/list";
  multiPropertyURL = 'v1/property/unassigned/cluster/multiple';

  globalCluster = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private messageService: MessageToastService
  ) {
    // super(http, "", messageService);
  }

  setGlobalCluster(cluster) {
    console.log('setting global cluster in service');
    this.globalCluster.next(cluster);
  }

  saveClusterData(data) {
    return this.http.post(environment.serverURl + this.clusterURL, data);
  }

  updateClusterData(data) {
    return this.http.put(environment.serverURl + this.clusterURL, data);
  }

  deleteClusterData(uuid) {
    return this.http.delete(
      environment.serverURl + this.clusterURL + "uuid=" + uuid
    );
  }

  getClusterList() {
    return this.http.get(environment.serverURl + this.getClusterListURL);
  }

  getJunkClusterList() {
    return  this.http.get(environment.serverURl + this.junkClusterListURL);
  }

  assignCluster(prop_id, cluster_id) {
    return this.http.put(environment.serverURl + this.assignClusterURL, {
      property_id: prop_id,
      cluster_id: cluster_id
    });
  }

  saveClusterAdminData(data) {
    return this.http.post(environment.serverURl + this.clusterAdminURL, data);
  }

  updateClusterAdminData(data) {
    return this.http.put(environment.serverURl + this.clusterAdminURL, data);
  }

  deleteClusterAdminData(uuid) {
    return this.http.delete(
      environment.serverURl + this.clusterAdminURL + "uuid=" + uuid
    );
  }

  getClusterAdminDetails() {
    return this.http.get(environment.serverURl + this.clusterAdminList);
  }

  multiplePropertyAssignment(data) {
    return this.http.put(environment.serverURl + this.multiPropertyURL, data);
  }
}
