import { DatePipe } from "@angular/common";
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { MatSidenav } from "@angular/material";
import { Subscription } from "rxjs/internal/Subscription";
import { elementAt, filter } from "rxjs/operators";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
import { MenuToggleService } from "src/app/common/service/menu-toggle/menu-toggle.service";
import { ReportingService } from "src/app/common/service/reporting/reporting.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
declare var $: any;

@Component({
  selector: 'app-stage-analytics',
  templateUrl: './stage-analytics.component.html',
  styleUrls: ['./stage-analytics.component.scss']
})
export class StageAnalyticsComponent implements OnInit {
  dataFreq = "Monthly Data";
  form: any;

  discreetStageChartData = [];
  discreetStatusChartData = [];
  cumulativeStageChartData = [];
  cumulativeStatusChartData = [];
  stageView: any = [2000, 400];
  statusView: any = [2000, 400];
  selectedCluster = null;
  subscription = new Subscription();
  stageDataFlag = false;
  statusDataFlag = false;
  globalStageList = [];
  globalStatusList = [];
  minDate;
  maxDate;
  selectedStages = [];
  prevSelectedStages = [];
  selectedStatuses = [];
  prevSelectedStatuses = [];
  selectedBtn = "discreet";

  regionList = [{ label: "Any Region", value: "" },
  { label: "North", value: "North" }, { label: "East", value: "East" },
  { label: "West", value: "West" }, { label: "South", value: "South" }];

  //chart Options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  tooltipDisabled = false;
  xAxisLabel = "Months";
  showYAxisLabel = true;
  yAxisLabel_stage = "Stages";
  yAxisLablel_status = "Statuses";
  showGridLines = true;
  innerPadding = 0;
  barPadding = 2;
  groupPadding = 16;
  roundDomains = false;
  maxRadius = 10;
  minRadius = 3;
  filteredStageChartData;
  filteredStatusChartData;

  reverseMonthMap = {
    Jan: '0',
    Feb: '1',
    Mar: '2',
    Apr: '3',
    May: '4',
    Jun: '5',
    Jul: '6',
    Aug: '7',
    Sep: '8',
    Oct: '9',
    Nov: '10',
    Dec: '11'
  };

  colors = [
    "#90caf9",
    "#1a4e6b",
    "#f4ab33",
    "#c068a8",
    "#ec7176",
    "#bb4e4b",
    "#43A047",
    "#795548",
    '#BD9FA0',
    "#FAF0EB",
    "#EBB9BB",
    "#BAB9BD",
    "#73FFC9",
    "#739EFF"
  ];
  colorScheme = {
    domain: this.colors
  };
  schemeType = "ordinal";

  @ViewChild("sidenav", { static: true }) sidenavbar: MatSidenav;
  constructor(
    private fb: FormBuilder,
    private reportingService: ReportingService,
    private toast: MessageToastService,
    private clusterService: ClusterService,
    private datepipe: DatePipe,
    private menuToggle: MenuToggleService
  ) { }

  ngOnInit() {
    this.maxDate = new Date();
    this.minDate = new Date((new Date()).getTime() - 365 * 24 * 60 * 60 * 1000);

    this.stageView = null;
    this.statusView = null;
    this.form = this.fb.group({
      year: [new Date(), Validators.required],
      region: [""]
    });

    // this.menuToggle.toggleMenu(false);

    this.subscription.add(
      this.clusterService.globalCluster.subscribe(val => {
        if (val) {
          this.selectedCluster = val;
          $(".funnel-filter-container").click();
          this.submit();
        }
      })
    );

    this.menuToggle.menuToggle.subscribe(val => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, (100));
    });
  }

  sidenavToggle() {
    this.sidenavbar.toggle();
  }

  submit() {
    console.log("submitted data");
    let data = [];
    data["year"] = this.datepipe.transform(new Date(
      Date.UTC(
        this.form
          .get('year')
          .value.toString()
          .split(' ')[3],
        this.reverseMonthMap[
        this.form
          .get('year')
          .value.toString()
          .split(' ')[1]
        ]
      )
    ).getTime(), "yyyy").toString();
    data["region"] = this.form.get("region").value;

    console.log(data);

    // let sources = await this.reportingService.getGlobalSource(data).toPromise();
    // this.globalSource = [];
    // this.globalSource.push(...sources["payload"].source_list);

    // console.log("global source right now");
    // console.log(this.globalSource);

    // global stage report
    this.reportingService.getStageAnalytics(data).subscribe((val: any) => {
      if (val && val.status) {
        if (Object.keys(val.payload.data).length > 0) {
          this.toast.success("STAGE DATA FETCHED SUCCESSFULLY");
          this.globalStageList = [];
          this.globalStageList.push(...val.payload.stages);
          this.getChartData(val, "stage");
          this.stageDataFlag = true;
        } else {
          this.stageDataFlag = false;
        }
      } else {
        this.toast.error(val["message"].toUpperCase());
        this.stageDataFlag = false;
      }
    });

    // global status report
    this.reportingService.getStatusAnalytics(data).subscribe((val: any) => {
      if (val && val.status) {
        if (Object.keys(val.payload.data).length > 0) {
          this.toast.success("STATUS DATA FETCHED SUCCESSFULLY");
          this.globalStatusList = [];
          this.globalStatusList.push(...val.payload.status);
          this.getChartData(val, "status");
          this.statusDataFlag = true;
        } else {
          this.statusDataFlag = false;
        }
      } else {
        this.toast.error("NO DATA");
        this.statusDataFlag = false;
      }
    });

    this.sidenavbar.close();
  }

  getChartData(val, source) {
    let columns = Object.keys(val["payload"].data).length;
    let width = columns < 10 ? 80 * columns : 10 * columns + 90;

    if (source == "stage") {
      this.discreetStageChartData = this.correctData(val["payload"].data.discreet, source);
      this.cumulativeStageChartData = this.correctData(val["payload"].data.cumulative, source);
      this.filteredStageChartData = this.discreetStageChartData;
      console.log("corrected data");
      console.log(this.discreetStageChartData);
      this.stageView = null;
      this.prevSelectedStages = [];
      this.selectedStages = ['all'];
      this.dataFilter(source);
    } else {
      this.discreetStatusChartData = this.correctData(val["payload"].data.discreet, source);
      this.cumulativeStatusChartData = this.correctData(val["payload"].data.cumulative, source);
      this.filteredStatusChartData = this.discreetStatusChartData;
      console.log("corrected data");
      console.log(this.discreetStatusChartData);
      this.statusView = null;
      this.prevSelectedStatuses = [];
      this.selectedStatuses = ['all'];
      this.dataFilter('status');
    }
  }

  correctData(data, source): any {
    console.log("data we are correcting");
    console.log(data);
    let graphData: any = [];
    Object.keys(data).forEach(key => {

      let obj = {};
      obj["name"] = key;
      obj["series"] = [];

      const colorList = source == 'stage' ? this.globalStageList : this.globalStatusList;

      colorList.forEach(ele => {
        if (data[key][ele]) {
          obj["series"].push({ name: ele, value: data[key][ele] });
        } else {
          obj["series"].push({ name: ele, value: 0 });
        }
      });

      graphData.push(obj);
    });
    return graphData;
  }

  dataFilter(type = 'stage') {
    if (type == 'stage') {
      console.log("stage list", this.selectedStages);
      let newData = [];

      if (this.selectedStages.indexOf("all") >= 0) {

        // 'all' was not clicked, since something else is toggled, 'all' needs to be removed
        if (this.prevSelectedStages.indexOf("all") >= 0 && this.selectedStages.length != this.globalStageList.length + 1) {
          console.log("removing all");
          this.selectedStages.splice(this.selectedStages.indexOf("all"), 1);
          // this.selectedStages = [];
          console.log("after removing", this.selectedStages);
        } else {
          this.selectedStages = ["all"];
          this.selectedStages.push(...this.globalStageList);
        }
      } else {
        if (this.prevSelectedStages.indexOf("all") >= 0 && this.selectedStages.length == this.globalStageList.length) {
          this.selectedStages = [];
        } else if (this.selectedStages.length == this.globalStageList.length) {
          this.selectedStages.push('all');
        }
      }

      this.prevSelectedStages = JSON.parse(JSON.stringify(this.selectedStages));
      console.log('prev list', this.prevSelectedStages);

      const filterData = this.selectedBtn == 'discreet' ? JSON.parse(JSON.stringify(this.discreetStageChartData)) : JSON.parse(JSON.stringify(this.cumulativeStageChartData));

      filterData.forEach((ele: any) => {
        let arr = [];
        ele.series.forEach((obj: any) => {
          if (this.selectedStages.indexOf(obj.name) >= 0) {
            arr.push(obj);
          } else {
            arr.push({ name: obj.name, value: 0 });
          }
        });
        newData.push({ name: ele.name, series: arr });
      });
      this.filteredStageChartData = JSON.parse(JSON.stringify(newData));
    } else {
      console.log("status list", this.selectedStatuses);
      let newData = [];

      if (this.selectedStatuses.indexOf("all") >= 0) {

        // 'all' was not clicked, since something else is toggled, 'all' needs to be removed
        if (this.prevSelectedStatuses.indexOf("all") >= 0  && this.selectedStatuses.length != this.globalStatusList.length + 1) {
          console.log("removing all");
          this.selectedStatuses.splice(this.selectedStatuses.indexOf("all"), 1);
          // this.selectedStatuses = [];
          console.log("after removing", this.selectedStatuses);
        } else {
          this.selectedStatuses = ["all"];
          this.selectedStatuses.push(...this.globalStatusList);
        }
      } else {
        if (this.prevSelectedStatuses.indexOf("all") >= 0 && this.selectedStatuses.length == this.globalStatusList.length) {
          this.selectedStatuses = [];
        } else if (this.selectedStatuses.length == this.globalStatusList.length) {
          this.selectedStatuses.push('all');
        }
      }

      this.prevSelectedStatuses = JSON.parse(JSON.stringify(this.selectedStatuses));
      console.log('prev list', this.prevSelectedStatuses);

      const filterStatusData = this.selectedBtn == 'discreet' ? JSON.parse(JSON.stringify(this.discreetStatusChartData)) : JSON.parse(JSON.stringify(this.cumulativeStatusChartData));

      filterStatusData.forEach((ele: any) => {
        let arr = [];
        ele.series.forEach((obj: any) => {
          if (this.selectedStatuses.indexOf(obj.name) >= 0) {
            arr.push(obj);
          } else {
            arr.push({ name: obj.name, value: 0 });
          }
        });
        newData.push({ name: ele.name, series: arr });
      });
      this.filteredStatusChartData = JSON.parse(JSON.stringify(newData));
      this.statusView = null;
    }
  }

  onValChange(val) {
    console.log("changing value", val);
    this.selectedBtn = val;
    this.dataFilter('stage');
    this.dataFilter('status');
  }
  

  getTooltipTime(val) {
    return val;
  }

  onChange(date) { }

}
