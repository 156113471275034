import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { LeadService } from "src/app/common/service/lead/lead.service";
import { Observable, Subscription } from "rxjs";
import { ListItemDirective } from "src/app/common/directive/list-select/list-item.directive";
declare var $: any;
import { ClusterService } from 'src/app/common/service/cluster/cluster.service';

@Component({
  selector: "app-stage-bar",
  templateUrl: "./stage-bar.component.html",
  styleUrls: ["./stage-bar.component.scss"]
})
export class StageBarComponent implements OnInit {
  @Input() inputSideNav: MatSidenav;
  @Input() uuid: string;
  @Input() events: Observable<void>;
  @Output() stageDetails: EventEmitter<any> = new EventEmitter();

  list_data;
  selectedItem;
  default_uuid;
  classList = ['green-milestone', 'red-milestone', 'blue-milestone'];
  private eventsSubscription: Subscription;
  private subscription: Subscription = new Subscription();

  constructor(private leadService: LeadService, 
    private clusterService: ClusterService) {}

  ngOnInit() {
    this.inputSideNav.toggle();
    this.subscription.add(
      this.clusterService.globalCluster.subscribe(gCluster => {
        if(gCluster) {
          if (this.uuid != "create") {
            this.getPropertyDetails();
          } else {
            this.leadService.getDefaultStage({uuid: gCluster.uuid}).subscribe(val => {
              console.log("stage bar create");
              console.log(val);
              this.list_data = val;
              this.onClick(
                val["payload"].data.current_stage.name,
                val["payload"].data.current_stage.uuid,
                val["payload"].data.current_stage.completed_on
              );
            });
          }
        }
      })
    );

  }

  ngAfterViewInit() {
    this.eventsSubscription = this.events.subscribe(() => {
      console.log('refreshing the stage bar');
      this.getPropertyDetails();
    });
  }



  colorTimeline() {
    setTimeout(() => {
      // color previous stages
      this.list_data.payload.data.previous_stage.forEach((stage, idx) => {
        const ele = document.getElementsByClassName('is-completed')[idx];
        if (stage.deadline != null && stage.deadline < stage.completed_on) {
          this.addClass(ele, 'red-milestone');
        } else {
          this.addClass(ele, 'green-milestone');
        }
      });

      // color current stages
      const curStage = this.list_data.payload.data.current_stage;
      const ele = document.getElementsByClassName('is-current')[0];
      if (curStage.completed_on != null) {
        if (curStage.deadline < curStage.completed_on) {
          this.addClass(ele, 'red-milestone');
        } else {
          this.addClass(ele, 'green-milestone');
        }

      } else {
        if (curStage.deadline < Math.floor((new Date()).getTime() / 1000)) {
          this.addClass(ele, 'red-milestone');
        } else {
          this.addClass(ele, 'blue-milestone');
        }
      }
    }, 300);
  }

  addClass(ele, className) {
    this.classList.forEach((color) => {
      ele.classList.remove(color);
    });

    ele.classList.add(className);
  }


  getPropertyDetails() {
    this.leadService.getPropertyDetails(this.uuid).subscribe(val => {
      console.log("not create, list data");
      console.log(val);
      this.list_data = val;
      // this.stageDetails.emit({
      //   stage_uuid: val["payload"].data.current_stage.uuid,
      //   stage_name: val["payload"].data.current_stage.name,
      //   stage_completed: val["payload"].data.current_stage.completed_on
      // });
      this.markActiveItem();
      this.colorTimeline();
    });
    
  }

  markActiveItem() {
    setTimeout(()=>{
      $('.active-item').click();
    },200);
  }

  onClick(name, uuid, completed_on, listDirective=null) {
    console.log("clicked stage bar item");
    console.log(name);
    console.log("uuid of item clicked");
    console.log(uuid);
    this.stageDetails.emit({
      stage_uuid: uuid,
      stage_name: name,
      stage_on: completed_on
    });
    if(listDirective) {
      (<ListItemDirective>listDirective).toggle();
    }
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
}
