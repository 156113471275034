import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LeadService } from "src/app/common/service/lead/lead.service";
import { FieldConfig } from "src/app/common/component/dynamic-form/field.interface";
import { DynamicFormComponent } from "src/app/common/component/dynamic-form/dynamic-form/dynamic-form.component";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { DatePipe } from "@angular/common";
import { BehaviorSubject, Subject } from "rxjs";
import { AccountService } from "src/app/common/service/account/account.service";
import { ClusterService } from 'src/app/common/service/cluster/cluster.service';
import { MenuToggleService } from 'src/app/common/service/menu-toggle/menu-toggle.service';

@Component({
  selector: "app-stage-view",
  templateUrl: "./stage-view.component.html",
  styleUrls: ["./stage-view.component.scss"]
})
export class StageViewComponent implements OnInit {
  @ViewChild(DynamicFormComponent, { static: false }) form: DynamicFormComponent;

  para: string;
  lead_uuid: string;
  cluster_uuid: string;
  stage_uuid: string;
  regConfig: FieldConfig[];
  access: string;
  valid: boolean;
  mapCheck: boolean = false;
  stage_name: string = "";
  isCompleted: boolean = false;
  cardCollapsed = true;
  propertyData : any;

  stageSubmitted: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private leadService: LeadService,
    private toast: MessageToastService,
    private accountService: AccountService,
    private datepipe: DatePipe,
    private clusterService: ClusterService,
    private menuToggle: MenuToggleService,
  ) {}

  ngOnInit() {
    this.clusterService.globalCluster.subscribe(gCluster => {
      if(gCluster) {
        this.cluster_uuid = gCluster.uuid;
      }
    });
    this.route.params.subscribe(val => {
      this.lead_uuid = val.id;      
      if (this.lead_uuid === "create") {
        this.para = "creating new lead";
      } else {
        this.para = "updating existing lead";
        this.getPropertyData({property_id: this.lead_uuid});
      }
    });
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 200);
    // resizing the content whenever menu is toggled
    this.menuToggle.menuToggle.subscribe(val => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 200);
    });
  }

  getPropertyData(data) {
    this.accountService.getPropertyDetails(data).subscribe(res => {
      if(res && res['status']) {
        console.log('property details found out');
        console.log(res);
        this.propertyData = res['payload'];
      }
    });
  }

  valid_check(event) {
    console.log("received value");
    console.log(event);
    this.valid = event;

    console.log("valid value");
    console.log(this.valid);
  }

  onStageChange(event) {
    this.stage_uuid = event.stage_uuid;
    this.stage_name = event.stage_name;
    this.isCompleted = event.stage_on != "" ? true : false;
    this.regConfig = null;
    console.log("stage change event");
    console.log(event);
    //console.log('setting stage uuid as '+ event);
    if (this.lead_uuid != "create") {
      console.log("inside update");
      this.para = event;
      this.leadService
        .getPropertyStage(this.lead_uuid, this.stage_uuid)
        .subscribe(val => {
          console.log("printing the cleaned value");
          console.log(val);
          if (val["payload"].stage) {
            this.access = val["payload"].stage.access;
            this.regConfig = [...val["payload"].stage.fields];
          }
          this.checkData();
          console.log(this.regConfig);
        });
    } else {
      this.leadService.getDefaultStage({uuid: this.cluster_uuid}).subscribe(val => {
        console.log(val);
        console.log("inside create default stage");
        this.access = val["payload"].data.current_stage.access;
        this.regConfig = [...val["payload"].data.current_stage.fields];
        console.log(this.regConfig);
      });
    }
  }

  checkData() {
    let checkMap: boolean = true; // to check if all the fields are map-type

    if (this.regConfig && this.regConfig.length != 0) {
      this.regConfig.forEach(field => {
        if (field.type != "map") {
          checkMap = false;
        }
      });
    } else {
      checkMap = false;
    }

    this.mapCheck = checkMap;
  }

  submit(event) {
    console.log("form successfully submitted");
    console.log(event);
    console.log("regconfig right now");
    console.log(this.regConfig);

    let typeList = {};
    let formData = {};//JSON.parse(JSON.stringify(event));
    
    this.regConfig.forEach(val => {
      typeList[val.name] = val.type;
    });

    console.log('printing the typelist file');
    console.log(typeList);

    Object.keys(event).forEach(ele => {

      if(typeList[ele] == "date") {
        if(event[ele])
        {formData[ele] = this.datepipe.transform(event[ele], 'yyyy-MM-dd'); console.log('date changed');}
        
      }
      else if(typeList[ele] == "datetime") {
        console.log('form element right now');
        console.log(event[ele]);
        if(event[ele])
        {formData[ele] = Math.floor(event[ele].getTime() / 1000);} // - 19800;
      }
      else{
        formData[ele] = event[ele];
      }
    });

    console.log('changed form value');
    console.log(event);

    if (this.lead_uuid == "create") {
      formData["stage_id"] = this.stage_uuid;
      formData["cluster_id"] = this.cluster_uuid;
      console.log("stage_id set as " + this.stage_uuid);

      this.leadService.addFirstStage(formData).subscribe(val => {
        console.log("printing response from creating the lead");
        console.log(val);
        if (val && val["status"]) {
          this.toast.success("LEAD CREATED SUCCESSFULLY");
          //this.form.clear();
          //this.stageSubmitted.next();
          //this.router.navigate(["lead/stage-view/" + val["payload"].data.uuid]);
          window.location.href = "lead/stage-view/" + val["payload"].data.uuid;
        } else {
          this.toast.error("OPERATION UNSUCCESSFUL");
        }
      });
    } else {
      formData["stage_id"] = this.stage_uuid;
      formData["property_id"] = this.lead_uuid;
      console.log('form value we are submitting');
      console.log(formData);
      this.leadService.updateStage(formData).subscribe(val => {
        console.log("printing response from updating the lead");
        console.log(val);
        if (val && val["status"]) {
          this.toast.success("LEAD UPDATED SUCCESSFULLY");
          if (this.regConfig.length == 0) {
            window.location.reload();
          }
          else {
            console.log('emitting value wwwwwwwwwwww');
            this.stageSubmitted.next();
            this.getPropertyData({property_id: this.lead_uuid});
          }
        } else {
          this.toast.error("OPERATION UNSUCCESSFUL");
        }
      });
    }
  }
}
