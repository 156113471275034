import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { DepartmentService } from "src/app/common/service/department/department.service";
import { MatDialog } from "@angular/material/dialog";
import { DeleteModalComponent } from "src/app/common/component/delete-modal/delete-modal.component";

@Component({
  selector: "app-cluster-department-management",
  templateUrl: "./cluster-department-management.component.html",
  styleUrls: ["./cluster-department-management.component.scss"]
})
export class ClusterDepartmentManagementComponent implements OnInit {
  rows = [];
  count = 0;
  offset = 0;
  limit = 10;
  selectedCities1 = [];
  isEditMode = false;
  clusterList = [];
  isFilterCollapsed = false
  isCollapsed = false;
  departmentList = [];
  departmentListDetails = [];
  filterApplied = false;
  form: FormGroup;
  departmentform: FormGroup;
  formValid: boolean = false;
  formValid1: boolean = false;

  constructor(
    private fb: FormBuilder,
    private departmentService: DepartmentService,
    private clusterService: ClusterService,
    private toast: MessageToastService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      cluster_id: ["", Validators.required]
    });

    this.departmentform = this.fb.group({
      uuid: [null],
      name: ["", Validators.required]
    });
    this.form.get("cluster_id").valueChanges.subscribe(() => {
      this.departmentform.reset();
      this.filterApplied = false;
      this.isEditMode = false;
    });
    this.getClusterDetails();
    this.clusterList.push({
      label: "Select a Cluster",
      value: null
    });

    this.form.valueChanges.subscribe(()=>{
      this.formValid = this.form.valid;
    });

    this.departmentform.valueChanges.subscribe(()=>{
      this.formValid1 = this.departmentform.valid;
    });
  }

  submit() {
    if (this.form.valid) {
      let data = {
        uuid: this.departmentform.get("uuid").value,
        name: this.departmentform.get("name").value,
        cluster_id: this.form.get("cluster_id").value
      };
      if (this.isEditMode) {
        this.departmentService
          .updateDepartmentData(data)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.reset();
              this.toast.success("CLUSTER UPDATED SUCCESSFULLY");
              this.getDepartmentDetails();
            }
          });
      } else {
        this.departmentService
          .saveDepartmentData(data)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.reset();
              this.toast.success("CLUSTER DEPARTMENT CREATED SUCCESSFULLY");
              this.getDepartmentDetails();
            }
          });
      }
    }
  }

  editCluster(row) {
    this.departmentform.patchValue({
      uuid: row.uuid,
      name: row.name
    });
    this.isEditMode = true;
  }

  deleteCluster(row) {
    let dialogRef = this.dialog.open(DeleteModalComponent);

    dialogRef.componentInstance.option.subscribe(data => {
      if(data == 'success') {
        this.departmentService
      .deleteDepartmentData(row.uuid)
      .subscribe((result: any) => {
        if (result && result.status) {
          this.reset();
          this.toast.success("CLUSTER DEPARTMENT DELETED SUCCESSFULLY");
          this.getDepartmentDetails();
        }
      });
      }
    });
  }

  reset() {
    this.departmentform.reset();
    this.isEditMode = false;
  }

  getDepartmentDetails() {
    if (this.form.valid) {
      let queryParams = "cluster_id=" + this.form.get("cluster_id").value;

      this.departmentService
        .getDepartmentList(queryParams)
        .subscribe((result: any) => {
          this.filterApplied = true;
          if (result && result.status) {
            this.departmentList = result.payload.data;
            this.departmentListDetails = [...this.departmentList];
            this.offset = 0;
          }
        });
    }
  }

  getClusterDetails() {
    this.clusterService.getClusterList().subscribe((result: any) => {
      if (result && result.status) {
        result.payload.clusters.forEach(element => {
          this.clusterList.push({
            label: element.identifier,
            value: element.uuid
          });
        });
      }
    });
  }

  onPage(event) {
    // console.log(event);
    // const start = event.offset * this.limit;
    // const end = start + this.limit;
    // const rows = [];
    // this.offset = event.offset;
    // let index = 0;
    // for (let i = start; i < end; i++) {
    //   rows[index] = this.departmentListDetails[i];
    //   index++;
    // }
    // console.log(rows);

    // this.departmentList = rows;
    // this.departmentList = [...this.departmentList];
  }
}
