import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseService } from "../base.service";
import { MessageToastService } from "../toast/message-toast.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class AssociateService {
  associateURL = "v1/account/associate?";
  associateListURL = "v1/account/associate/list";
  clusterAssociateListURL = 'v1/account/associate/list/cluster';
  departmentAssociateListURL = 'v1/account/associate/list/department';
  deleteAssociateURL = 'v1/account/associate/list/department';
  addAssociateURL = 'v1/account/associate';

  constructor(
    private http: HttpClient,
    private messageService: MessageToastService
  ) {
    // super(http, "", messageService);
  }

  saveAssociateData(data) {
    return this.http.post(environment.serverURl + this.associateURL, data);
  }

  updateAssociateData(data) {
    return this.http.put(environment.serverURl + this.associateURL, data);
  }

  deleteAssociateData(data) {
    return this.http.delete(
      environment.serverURl + this.associateURL, {
        params: data
      }
    );
  }

  getAssociateList() {
    return this.http.get(environment.serverURl + this.associateListURL);
  }

  getClusterAssociateList(data) {
    return this.http.get(environment.serverURl + this.clusterAssociateListURL, {
      params: data
    });
  }

  getDepartmentAssociateList(data) {
    return this.http.get(environment.serverURl + this.departmentAssociateListURL, {
      params: data
    });
  }

  deleteAssociateFromDepartment(associateUUID, departmentUUID) {
    return this.http.delete(environment.serverURl + this.deleteAssociateURL, {
      params: {department_id: departmentUUID, associate_id: associateUUID}
    });
  }

  addAssociateToDepartment(associateUUID, departmentUUID, emailID, clusterID) {
    return this.http.put(environment.serverURl + this.addAssociateURL, {
      department_ids: [departmentUUID], uuid: associateUUID, email: emailID, cluster_id: clusterID
    });
  }
}
