import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { StageService } from "src/app/common/service/stage/stage.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { DepartmentService } from "src/app/common/service/department/department.service";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
import { MatDialog } from "@angular/material/dialog";
import { DeleteModalComponent } from "src/app/common/component/delete-modal/delete-modal.component";

@Component({
  selector: "app-department-stage-access",
  templateUrl: "./department-stage-access.component.html",
  styleUrls: ["./department-stage-access.component.scss"]
})
export class DepartmentStageAccessComponent implements OnInit {
  rows = [];
  count = 0;
  offset = 0;
  limit = 10;
  clusterList = [];
  isCollapsed = false;
  isFilterCollapsed = false;
  stageList = [];
  stageAccessListData = [];
  departmentList = [];
  departmentListDetails = [];
  stageAccessList = [];
  filterApplied = false;
  form: FormGroup;
  filterForm: FormGroup;
  formValid: boolean = false;
  formValid1: boolean = false;

  accessList = [
    {
      label: "NA",
      value: "na"
    },
    {
      label: "View",
      value: "view"
    },
    {
      label: "Edit",
      value: "edit"
    }
  ];

  constructor(
    private fb: FormBuilder,
    private departmentService: DepartmentService,
    private clusterService: ClusterService,
    private stageService: StageService,
    private toast: MessageToastService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      stage_id: ["", Validators.required],
      department_id: ["", Validators.required],
      access_type: ["na"]
    });
    this.filterForm = this.fb.group({
      cluster_id: ["", Validators.required]
    });

    this.filterForm.get("cluster_id").valueChanges.subscribe(() => {
      this.form.reset();
      this.filterApplied = false;
    });
    this.getClusterDetails();
    this.clusterList.push({
      label: "Select a Cluster",
      value: null
    });

    this.filterForm.valueChanges.subscribe(() => {
      this.formValid = this.filterForm.valid;
    });

    this.form.valueChanges.subscribe(() => {
      this.formValid1 = this.form.valid;
    });
  }

  submit() {
    if (this.form.valid) {
      this.stageService
        .setStageDepartmentAccess(this.form.value)
        .subscribe((result: any) => {
          if (result && result.status) {
            this.reset();
            this.toast.success("STAGE DEPARTMENT ACCESS CREATED SUCCESSFULLY");
            this.getData();
          }
        });
    }
  }

  getData() {
    this.getDepartmentDetails();
    this.getStageDetails();
    this.getDepartmentAccessList();
  }

  reset() {
    this.form.reset();
    this.form.get("access_type").setValue("na");
    this.form.get("stage_id").setValue("");
    this.form.get("department_id").setValue("");
  }

  getDepartmentAccessList() {
    let queryParams = "uuid=" + this.filterForm.get("cluster_id").value;
    this.stageService
      .getStageDepartmentAccessList(queryParams)
      .subscribe((result: any) => {
        if (result.status) {
          result.payload.forEach(val=> {
            val['label'] = this.getAccessType(val.access_type);
          });
          this.stageAccessList = [...result.payload];
          this.stageAccessListData = result.payload;
          this.offset = 0;
        }
      });
  }

  deleteAccess(row) {
    let dialogRef = this.dialog.open(DeleteModalComponent);

    dialogRef.componentInstance.option.subscribe(data => {
      if (data == "success") {
        let querparams =
          "stage_id=" +
          row.stage.uuid +
          "&department_id=" +
          row.department.uuid;
        this.stageService
          .deleteStageDepartmentAccess(querparams)
          .subscribe((result: any) => {
            if (result.status) {
              this.getDepartmentAccessList();
              this.toast.success('STAGE DEPARTMENT ACCESS DELETED SUCCESSFULLY');
            }
          });
      }
    });
  }

  getAccessType(type) {
    let access = this.accessList.find(x => x.value == type);
    return access.label;
  }

  getDepartmentDetails() {
    if (this.filterForm.valid) {
      let queryParams = "cluster_id=" + this.filterForm.get("cluster_id").value;
      this.departmentList = [];
      this.departmentService
        .getDepartmentList(queryParams)
        .subscribe((result: any) => {
          this.filterApplied = true;
          this.departmentList.push({
            label: "Select a department",
            value: ""
          });
          if (result && result.status) {
            result.payload.data.forEach(element => {
              this.departmentList.push({
                label: element.name,
                value: element.uuid
              });
            });
            // this.departmentListDetails = [...this.departmentList];
            this.form.get("department_id").setValue("");
            this.form.get("access_type").setValue("na");
          }
        });
    }
  }

  getStageDetails() {
    if (this.filterForm.valid) {
      let queryParams = "uuid=" + this.filterForm.get("cluster_id").value;
      this.stageList = [];
      this.stageService.getStageList(queryParams).subscribe((result: any) => {
        this.filterApplied = true;
        this.stageList.push({
          label: "Select a stage",
          value: ""
        });

        if (result && result.status) {
          result.payload.stages.forEach(element => {
            this.stageList.push({
              label: element.display_name,
              value: element.uuid
            });
          });
          this.form.get("stage_id").setValue("");
        }
      });
    }
  }

  getClusterDetails() {
    this.clusterService.getClusterList().subscribe((result: any) => {
      if (result && result.status) {
        result.payload.clusters.forEach(element => {
          this.clusterList.push({
            label: element.identifier,
            value: element.uuid
          });
        });
      }
    });
  }

  // getDepartmentAccess(){
  //   let queryParams =
  //   this.stageService.getStageDepartmentAccessList()
  // }

  onPage(event) {}
  //   console.log(event);
  //   const start = event.offset * this.limit;
  //   const end = start + this.limit;
  //   const rows = [];
  //   this.offset = event.offset;
  //   let index = 0;
  //   for (let i = start; i < end; i++) {
  //     rows[index] = this.stageAccessListData[i];
  //     index++;
  //   }
  //   console.log(rows);

  //   this.stageAccessList = rows;
  //   this.stageAccessList = [...this.stageAccessList];
  // }
}
