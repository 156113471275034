import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LoggerService } from "src/app/common/service/logger/logger.service";
import { MatDialog } from "@angular/material/dialog";
import { IotDeviceModalComponent } from "src/app/common/component/iot-device-modal/iot-device-modal.component";
import { FormBuilder, Validators } from "@angular/forms";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { MenuToggleService } from "src/app/common/service/menu-toggle/menu-toggle.service";
declare var $: any;

@Component({
  selector: "app-iot-configuration",
  templateUrl: "./iot-configuration.component.html",
  styleUrls: ["./iot-configuration.component.scss"]
})
export class IotConfigurationComponent implements OnInit {
  isCollapsed: boolean = false;
  devices: any = "";
  dropdown_data: any = "";
  logger_dropdown_data: any = "";
  lead_uuid: any;
  form: any;
  check: string = "no_device";
  loggerData: any = null;
  api_method_dropdown: any;
  health_dropdown: any;
  logger_type_dropdown: any;
  meter_type_dropdown: any;
  net_meter_type_dropdown: any;
  sim_provider_dropdown: any;
  tariff_currency_dropdown: any;
  boolean_dropdown: any;
  request_type_dropdown: any;
  selected_tab: number = 0;

  constructor(
    private route: ActivatedRoute,
    private loggerService: LoggerService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private toast: MessageToastService,
    private menuToggle: MenuToggleService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      installed_on: [0, Validators.required],
      logger_activated_on: ["", Validators.required],
      logger_type: ["", Validators.required],
      logger_identifier: ["", Validators.required],
      installation_capacity: ["", Validators.required],
      last_logged_on: [0, Validators.required],
      solar_to_grid_max_energy: 0,
      solar_to_home_max_energy: 0,
      grid_to_home_max_energy: 0,
      current_max_energy: 0,
      reset_factor: [0, Validators.required],
      reset_factored_on: [0, Validators.required],
      logger_flag: ["", Validators.required],
      meter_flag: [0, Validators.required],
      net_meter_flag: [0, Validators.required],
      health: ["", Validators.required],
      tariff: ["", Validators.required],
      tariff_currency: ["", Validators.required],
      api_method: ["GET", Validators.required],
      api_url: [null, Validators.required],
      api_header: "",
      api_body: "",
      is_pulled: "",
      dongle_identifier: "",
      sim_phone_number: "",
      sim_country_code: "",
      sim_imei: "",
      wifi_available: ""
    });

    this.route.params.subscribe(val => {
      this.lead_uuid = val.id;
      this.getData();
    });

    this.menuToggle.toggleMenu(false);

    this.menuToggle.menuToggle.subscribe(val => {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 100);
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.form.valueChanges.subscribe(val => {
        console.log("somewhere value changed occured");
        // this.checkFormData();
      });

      // this.checkFormData();
      console.log("printing the form value after checking");
      console.log(this.form.value);
    }, 1000);
  }

  createErrorIcon(id) {
    let errorImg = document.createElement("IMG");
    errorImg["src"] = "assets/images/error-icon.png";
    errorImg.style.height = "18px";
    errorImg.style.width = "18px";
    errorImg.style.marginLeft = "10px";
    errorImg.id = id;

    return errorImg;
  }

  checkFormData() {
    let tab1 = $("#mat-tab-label-0-0")[0];
    let tab2 = $("#mat-tab-label-0-1")[0];
    let tab3 = $("#mat-tab-label-0-2")[0];

    let e1 = this.createErrorIcon("error-tab-1");
    let e2 = this.createErrorIcon("error-tab-2");
    let e3 = this.createErrorIcon("error-tab-3");

    if ($("#error-tab-1")) {
      $("#error-tab-1").remove();
    }

    if ($("#error-tab-2")) {
      $("#error-tab-2").remove();
    }

    if ($("#error-tab-3")) {
      $("#error-tab-3").remove();
    }

    if (
      this.form.get("installed_on").hasError("required") ||
      this.form.get("logger_activated_on").hasError("required") ||
      this.form.get("logger_type").hasError("required") ||
      this.form.get("logger_identifier").hasError("required") ||
      this.form.get("installation_capacity").hasError("required") ||
      this.form.get("last_logged_on").hasError("required") ||
      this.form.get("reset_factor").hasError("required") ||
      this.form.get("reset_factored_on").hasError("required") ||
      this.form.get("logger_flag").hasError("required") ||
      this.form.get("meter_flag").hasError("required") ||
      this.form.get("net_meter_flag").hasError("required")
    ) {
      console.log("first tab has the error");
      console.log(tab1);

      tab1.appendChild(e1);
    }

    if (
      this.form.get("health").hasError("required") ||
      this.form.get("tariff").hasError("required") ||
      this.form.get("tariff_currency").hasError("required")
    ) {
      console.log("second tab has the error");
      console.log(tab2);
      tab2.appendChild(e2);
    }

    if (
      this.form.get("api_url").hasError("required") ||
      this.form.get("api_method").hasError("required")
    ) {
      console.log("third tab has the error");
      console.log(tab3);
      tab3.appendChild(e3);
    }
  }

  cleanData(val) {
    this.devices = [...val.payload.property_data.devices];
    this.dropdown_data = {
      device_type: val.payload.dropdown_data.device_type,
      device_provider: val.payload.dropdown_data.device_provider
    };

    // this.logger_dropdown_data = {
    //   api_method: val.payload.dropdown_data.api_method,
    //   health: val.payload.dropdown_data.health,
    //   logger_type: val.payload.dropdown_data.logger_type,
    //   meter_type: val.payload.dropdown_data.meter_type,
    //   net_meter_type: val.payload.dropdown_data.net_meter_type,
    //   sim_provider: val.payload.dropdown_data.sim_provider,
    //   tariff_currency: val.payload.dropdown_data.tariff_currency
    // };

    this.boolean_dropdown = [
      { key: "True", value: 1 },
      { key: "False", value: 0 }
    ];

    this.request_type_dropdown = [
      { key: "Pull", value: 1 },
      { key: "Push", value: 0 }
    ];

    if (val.payload.dropdown_data.api_method) {
      this.api_method_dropdown = [];
      Object.keys(val.payload.dropdown_data.api_method).forEach(idx => {
        this.api_method_dropdown.push({
          key: idx,
          value: val.payload.dropdown_data.api_method[idx]
        });
      });
    }

    if (val.payload.dropdown_data.health) {
      this.health_dropdown = [];
      Object.keys(val.payload.dropdown_data.health).forEach(idx => {
        this.health_dropdown.push({
          key: idx,
          value: val.payload.dropdown_data.health[idx]
        });
      });
    }

    if (val.payload.dropdown_data.logger_type) {
      this.logger_type_dropdown = [];
      Object.keys(val.payload.dropdown_data.logger_type).forEach(idx => {
        this.logger_type_dropdown.push({
          key: idx,
          value: val.payload.dropdown_data.logger_type[idx]
        });
      });
    }

    if (val.payload.dropdown_data.meter_type) {
      this.meter_type_dropdown = [];
      Object.keys(val.payload.dropdown_data.meter_type).forEach(idx => {
        this.meter_type_dropdown.push({
          key: idx,
          value: val.payload.dropdown_data.meter_type[idx]
        });
      });
    }

    if (val.payload.dropdown_data.net_meter_type) {
      this.net_meter_type_dropdown = [];
      Object.keys(val.payload.dropdown_data.net_meter_type).forEach(idx => {
        this.net_meter_type_dropdown.push({
          key: idx,
          value: val.payload.dropdown_data.net_meter_type[idx]
        });
      });
    }

    if (val.payload.dropdown_data.sim_provider) {
      this.sim_provider_dropdown = [];
      Object.keys(val.payload.dropdown_data.sim_provider).forEach(idx => {
        this.sim_provider_dropdown.push({
          key: idx,
          value: val.payload.dropdown_data.sim_provider[idx]
        });
      });
    }

    if (val.payload.dropdown_data.tariff_currency) {
      this.tariff_currency_dropdown = [];
      Object.keys(val.payload.dropdown_data.tariff_currency).forEach(idx => {
        this.tariff_currency_dropdown.push({
          key: idx,
          value: val.payload.dropdown_data.tariff_currency[idx]
        });
      });
    }

    this.loggerData = val["payload"].property_data.logger;

    if (this.loggerData && Object.keys(this.loggerData).length != 0) {
      this.check = "device_present";
    }

    this.reset();
  }

  addNewDevice(event) {
    let dialogRef = this.dialog.open(IotDeviceModalComponent, {
      data: { device_data: this.dropdown_data, uuid: this.lead_uuid }
    });

    dialogRef.componentInstance.status.subscribe(data => {
      if (data == true) {
        this.getData();
      }
    });
  }

  collapse(event) {
    console.log("clicked button event");
    console.log(event.srcElement.className);
    console.log(event.srcElement.className.indexOf("modal-bottom-button"));
    if (
      event.srcElement.className != "mat-button-wrapper" &&
      event.srcElement.className.indexOf("modal-bottom-button") == -1
    ) {
      this.isCollapsed = !this.isCollapsed;
    }
  }

  submit(action) {
    let formData = {};

    Object.keys(this.form.value).forEach(key => {
      formData[key] = this.form.value[key];
    });

    if (formData["installed_on"] && formData["installed_on"] != 0) {
      console.log("installed on received");
      console.log(formData["installed_on"]);
      formData["installed_on"] = Math.floor(
        formData["installed_on"].getTime() / 1000
      );
    }

    if (formData["last_logged_on"] && formData["last_logged_on"] != 0) {
      console.log("last logged on received");
      console.log(formData["last_logged_on"]);
      formData["last_logged_on"] = Math.floor(
        formData["last_logged_on"].getTime() / 1000
      );
    }

    if (formData["reset_factored_on"] && formData["reset_factored_on"] != 0) {
      console.log("reset factor on received");
      console.log(formData["reset_factored_on"]);
      formData["reset_factored_on"] = Math.floor(
        formData["reset_factored_on"].getTime() / 1000
      );
    }

    if (
      formData["logger_activated_on"] &&
      formData["logger_activated_on"] != 0
    ) {
      formData["logger_activated_on"] = Math.floor(
        formData["logger_activated_on"].getTime() / 1000
      );
    }

    console.log("form data submitting");
    console.log(formData);

    if (action == "update") {
      (<any>Object).values(this.form.controls).forEach(control => {
        control.markAsTouched();
      });
      this.checkFormData();
      if (this.form.invalid) {
        this.toast.error("PLEASE FILL MANDATORY FIELDS");
      } else {
        this.loggerService
          .updateLogger(formData, this.lead_uuid)
          .subscribe(res => {
            if (res && res["status"]) {
              this.toast.success("SUCCESSFULLY UPDATED LOGGER");
              this.getData();
            } else {
              this.toast.error("OPERATION FAILED");
            }
          });
      }
    } else {
      // create
      this.loggerService.addLogger(formData, this.lead_uuid).subscribe(res => {
        if (res && res["status"]) {
          this.toast.success("SUCCESSFULLY ADDED A LOGGER");
          this.getData();
        } else {
          this.toast.error("OPERATION FAILED " + res["message"].toUpperCase());
        }
      });
    }
  }

  reset() {
    console.log("resetting");
    console.log(this.loggerData);
    this.form.patchValue({
      installed_on:
        this.loggerData.installed_on != 0
          ? new Date(this.loggerData.installed_on * 1000)
          : null,
      installation_capacity: this.loggerData.installation_capacity,
      last_logged_on:
        this.loggerData.installed_on != 0
          ? new Date(this.loggerData.installed_on * 1000)
          : null,
      solar_to_grid_max_energy: this.loggerData.solar_to_grid_max_energy,
      solar_to_home_max_energy: this.loggerData.solar_to_home_max_energy,
      grid_to_home_max_energy: this.loggerData.grid_to_home_max_energy,
      current_max_energy: this.loggerData.current_max_energy,
      reset_factor: this.loggerData.reset_factor,
      reset_factored_on: this.loggerData.reset_factored_on != 0 ? new Date(this.loggerData.reset_factored_on * 1000) : null,
      logger_type: this.loggerData.logger_type,
      logger_identifier: this.loggerData.logger_identifier,
      logger_activated_on:
        this.loggerData.logger_activated_on != 0
          ? new Date(this.loggerData.logger_activated_on * 1000)
          : null,
      logger_flag: this.loggerData.logger_flag,
      meter_type: this.loggerData.meter_type,
      meter_identifier: this.loggerData.meter_identifier,
      meter_flag: this.loggerData.meter_flag,
      net_meter_flag: this.loggerData.net_meter_flag,
      health: this.loggerData.health,
      tariff: this.loggerData.tariff,
      tariff_currency: this.loggerData.tariff_currency,
      dongle_identifier: this.loggerData.dongle_identifier,
      sim_phone_number: this.loggerData.sim_phone_number,
      wifi_available: this.loggerData.wifi_available,
      sim_country_code: this.loggerData.sim_country_code,
      sim_imei: this.loggerData.sim_imei,
      api_method: this.loggerData.api_method,
      api_url: this.loggerData.api_url,
      api_header: this.loggerData.api_header,
      api_body: this.loggerData.api_body,
      is_pulled: this.loggerData.is_pulled
    });
  }

  refresh() {
    console.log("refreshing the whole page");
    this.getData();
  }

  addLogger() {
    this.check = "add_device";
    this.form.patchValue({
      installed_on: 0,
      last_logged_on: 0,
      solar_to_grid_max_energy: 0,
      solar_to_home_max_energy: 0,
      grid_to_home_max_energy: 0,
      current_max_energy: 0,
      installation_capacity: "",
      reset_factor: 0,
      reset_factored_on: 0,
      logger_type: "",
      logger_identifier: "",
      logger_activated_on: 0,
      logger_flag: 0,
      meter_type: "",
      meter_identifier: "",
      meter_flag: 0,
      net_meter_flag: 0,
      health: "",
      tariff: 0,
      tariff_currency: "",
      dongle_identifier: "",
      sim_phone_number: "",
      wifi_available: 0,
      sim_country_code: "",
      sim_imei: "",
      api_method: "",
      api_url: "",
      api_header: "",
      api_body: "",
      is_pulled: 0
    });
  }

  cancel() {
    this.check = "no_device";
  }

  getData() {
    this.loggerService.getLoggerDetails(this.lead_uuid).subscribe(res => {
      console.log("data from logger service");
      console.log(res);
      this.cleanData(res);
    });
  }

  onTabChange(event) {
    console.log("tab changed");
    console.log(event);
    this.selected_tab = event.index;
  }
}
