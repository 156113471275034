import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { DepartmentService } from "src/app/common/service/department/department.service";
import { AssociateService } from "src/app/common/service/associate/associate.service";

@Component({
  selector: "app-associate-management",
  templateUrl: "./associate-management.component.html",
  styleUrls: ["./associate-management.component.scss"]
})
export class AssociateManagementComponent implements OnInit {
  rows = [];
  count = 0;
  offset = 0;
  limit = 10;
  selectedCities1 = [];
  departmentList = [];
  isEditMode = false;
  isCollapsed = false;
  clusterAdminList = [];
  clusterAdminDetails = [];
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private departmentService: DepartmentService,
    private associateService: AssociateService,
    private toast: MessageToastService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      uuid: [null],
      name: ["", Validators.required],
      email: ["", Validators.required],
      password: ["", Validators.required],
      phone_number: ["", Validators.required],
      country_code: ["91", Validators.required],
      department_ids: ["", Validators.required]
    });
    this.getAssociateDetails();
    this.getDepartmentDetails();
  }

  submit() {
    if (this.form.valid) {
      if (this.isEditMode) {
        this.associateService
          .updateAssociateData(this.form.value)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.reset();
              this.toast.success("ASSOCIATE UPDATED SUCCESSFULLY");
              this.getAssociateDetails();
            }
          });
      } else {
        this.associateService
          .saveAssociateData(this.form.value)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.reset();
              this.toast.success("ASSOCIATE CREATED SUCCESSFULLY");
              this.getAssociateDetails();
            }
          });
      }
    }
  }

  editAccount(row) {
    let departmentList = [];
    row.departments.forEach(element => {
      departmentList.push(element.uuid);
    });
    this.form.patchValue({
      uuid: row.uuid,
      name: row.name,
      email: row.email,
      password: row.password,
      phone_number: row.phone_number,
      country_code: row.country_code,
      department_ids: departmentList
    });
    this.form.get("password").disable();
    this.isEditMode = true;
  }

  deleteAccount(row) {
    this.associateService
      .deleteAssociateData(row.uuid)
      .subscribe((result: any) => {
        if (result && result.status) {
          this.reset();
          this.toast.success("ASSOCIATE DELETED SUCCESSFULLY");
          this.getAssociateDetails();
        }
      });
  }

  reset() {
    this.form.reset();
    this.form.get("password").enable();
    this.form.get("country_code").setValue("91");
    this.isEditMode = false;
  }

  getAssociateDetails() {
    this.associateService.getAssociateList().subscribe((result: any) => {
      if (result && result.status) {
        this.clusterAdminList = result.payload.data;
        this.clusterAdminDetails = [...this.clusterAdminList];
        this.offset = 0;
      }
    });
  }

  getDepartmentDetails() {
    this.departmentService.getDepartmentList().subscribe((result: any) => {
      if (result && result.status) {
        this.departmentList = result.payload.data;
      }
    });
  }

  onPage(event) {
    console.log(event);
    const start = event.offset * this.limit;
    const end = start + this.limit;
    const rows = [];
    this.offset = event.offset;
    let index = 0;
    for (let i = start; i < end; i++) {
      rows[index] = this.clusterAdminDetails[i];
      index++;
    }
    console.log(rows);

    this.clusterAdminList = rows;
    this.clusterAdminList = [...this.clusterAdminList];
  }
}
