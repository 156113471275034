import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivityLogService {
  baseURL: any;
  createReminderURL = 'v1/property/reminder';
  activityLogListURL = 'v1/property/activitylog/list';
  createActivityLogURL = 'v1/property/activitylog';
  assignmentListURL = 'v1/property/activitylog/filter/assigneduser/list';
  completeReminderURL = 'v1/property/reminder/complete';

  constructor(private http: HttpClient) {
    this.baseURL = environment.serverURl;
  }

  completeReminder(data) {
    return this.http.put(this.baseURL + this.completeReminderURL, data);
  }

  getAssignmentList() {
    return this.http.get(this.baseURL + this.assignmentListURL);
  }

  setReminder(data) {
    return this.http.post(this.baseURL + this.createReminderURL, data);
  }

  getActivityLogList(data) {
    return this.http.get(this.baseURL + this.activityLogListURL, {
      params: data
    });
  }

  createActivityLog(data) {
    return this.http.post(this.baseURL + this.createActivityLogURL, data);
  }
}
