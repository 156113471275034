import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { AccountService } from "src/app/common/service/account/account.service";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
import { MatDialog } from "@angular/material/dialog";
import { DeleteModalComponent } from "src/app/common/component/delete-modal/delete-modal.component";

@Component({
  selector: "app-cluster-management",
  templateUrl: "./cluster-management.component.html",
  styleUrls: ["./cluster-management.component.scss"]
})
export class ClusterManagementComponent implements OnInit {
  rows = [];
  count = 0;
  offset = 0;
  limit = 10;
  selectedCities1 = [];
  isEditMode = false;
  clusterList = [];
  isCollapsed = false;
  clusterListDetails = [];
  form: FormGroup;
  formValid: boolean = false;

  constructor(
    private fb: FormBuilder,
    private clusterService: ClusterService,
    private toast: MessageToastService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      uuid: [null],
      identifier: ["", Validators.required]
    });
    this.getClusterDetails();

    this.form.valueChanges.subscribe(()=> {
      this.formValid = this.form.valid;
    });
  }

  submit() {
    if (this.form.valid) {
      if (this.isEditMode) {
        this.clusterService
          .updateClusterData(this.form.value)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.reset();
              this.toast.success("CLUSTER UPDATED SUCCESSFULLY");
              this.getClusterDetails();
            }
          });
      } else {
        this.clusterService
          .saveClusterData(this.form.value)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.reset();
              this.toast.success("CLUSTER CREATED SUCCESSFULLY");
              this.getClusterDetails();
            }
          });
      }
    }
  }

  editCluster(row) {
    this.form.patchValue({
      uuid: row.uuid,
      identifier: row.identifier
    });
    this.isEditMode = true;
  }

  deleteCluster(row) {
    let dialogRef = this.dialog.open(DeleteModalComponent);

    dialogRef.componentInstance.option.subscribe(data => {
      if(data == 'success') {
        this.clusterService.deleteClusterData(row.uuid).subscribe((result: any) => {
          if (result && result.status) {
            this.reset();
            this.toast.success("CLUSTER DELETED SUCCESSFULLY");
            this.getClusterDetails();
          }
        });
      }
    });

    
  }

  reset() {
    this.form.reset();
    this.onPage({count: this.clusterList.length, pageSize: 10, limit: this.limit, offset: 0});
    this.isEditMode = false;
  }

  getClusterDetails() {
    this.clusterService.getClusterList().subscribe((result: any) => {
      if (result && result.status) {
        this.clusterList = result.payload.clusters;
        this.clusterListDetails = [...this.clusterList];
      }
    });
  }

  onPage(event) {
    // console.log(event);
    // const start = event.offset * this.limit;
    // const end = start + this.limit;
    // const rows = [];
    // this.offset = event.offset;
    // let index = 0;
    // for (let i = start; i < end; i++) {
    //   rows[index] = this.clusterListDetails[i];
    //   index++;
    // }
    // console.log(rows);

    // this.clusterList = rows;
  }
}
