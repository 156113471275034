import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MessageToastService } from '../../service/toast/message-toast.service';
import { FormBuilder } from '@angular/forms';
import { ActivityLogService } from '../../service/activity-log/activity-log.service';
import { LeadService } from 'src/app/common/service/lead/lead.service';

@Component({
  selector: 'app-create-note',
  templateUrl: './create-note.component.html',
  styleUrls: ['./create-note.component.scss']
})
export class CreateNoteComponent implements OnInit {
  
  form: any;
  current_user: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateNoteComponent>,
    private toast: MessageToastService,
    private fb: FormBuilder,
    private activityLogService: ActivityLogService,
    private leadService: LeadService,
    ) {}
    
    ngOnInit() {
      this.form = this.fb.group({
        call_outcome: '',
        lead_uuid: '',
        lead_property_uuid: '',
        logged_on: new Date(),
        note: ''
      });
      
    }
   
    submit() {
      console.log(this.data);
      if (this.checkValid()) {
        let formData = JSON.parse(JSON.stringify(this.form.value));
        formData['log_type'] = 'log'
        formData['logged_on'] = Math.floor(
          this.form.get('logged_on').value.getTime() / 1000);
          formData['lead_uuid'] = this.data["lead_uuid"];
          formData['lead_property_uuid'] = this.data["lead_property_uuid"];
          this.activityLogService.createActivityLog(formData).subscribe(val => {
            if (val && val['status']) {
              this.updateLMSStatus();
            } else {
              this.toast.error('Something Went Wrong');
            }
          });
          
        } else {
          this.toast.error('Invalid Form');
        }
      }
      

      updateLMSStatus(){
        this.leadService.updateLmsStatus(this.data).subscribe(val => {
          if (val && val['status']) {
            this.toast.success('Lead status changed successfully.');
            this.dialogRef.close("submitted");
          } else {
            this.toast.error('Something Went Wrong');
          }
        });
      }
      checkValid() {
        let noteField = this.form.get('note');
        if (noteField.value && noteField.value != '') {
          return true;
        } else {
          return false;
        }
      }
      
      cancel() {
        this.dialogRef.close();
      }
      
    }
    