import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { DataSyncService } from 'gojs-angular';
import { GojsAngularModule } from 'gojs-angular';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import {
  MenuComponent,
  HeaderComponent,
  SidebarComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective
} from './core';
import { ResetPasswordComponent } from './common/component/reset-password/reset-password.component';
import { LoginComponent } from './core/account-management/login/login.component';
import { LeadNoPropertyComponent } from './core/lead-management/lead-no-property/lead-no-property.component';
import { LeadListViewComponent } from './core/lead-management/lead-list-view/lead-list-view.component';
import { AddPropertyComponent } from './common/component/add-property/add-property.component';
import { UserManagementComponent } from './core/account-management/user-management/user-management.component';
import { ClusterManagementComponent } from './core/cluster-management/cluster-management/cluster-management.component';
import { ClusterDepartmentManagementComponent } from './core/cluster-management/cluster-department-management/cluster-department-management.component';
import { ClusterAdminManagementComponent } from './core/cluster-management/cluster-admin-management/cluster-admin-management.component';
import { PropertyClusterAssignmentComponent } from './core/lead-management/property-cluster-assignment/property-cluster-assignment.component';
import { LoginGuard } from './common/service/login-guard/login.guard';
import { AssociateManagementComponent } from './core/associate-management/associate-management/associate-management.component';
import { AuthGuard } from './common/service/auth-guard/auth.guard';
import { MenuService } from './common/service/sidebar/menu.service';
import { Error4Component } from './common/component/error/error4/error4.component';
import { AddClusterComponent } from './common/component/add-cluster/add-cluster.component';
import { TokenInterceptorService } from './common/service/token-interceptor/token-interceptor.service';
import { StageViewComponent } from './core/lead-management/stage-view/stage-view.component';
import { StageBarComponent } from './core/lead-management/stage-bar/stage-bar.component';
import { ButtonComponent } from './common/component/dynamic-form/button/button.component';
import { SelectComponent } from './common/component/dynamic-form/select/select.component';
import { DateComponent } from './common/component/dynamic-form/date/date.component';
import { RadiobuttonComponent } from './common/component/dynamic-form/radiobutton/radiobutton.component';
import { CheckboxComponent } from './common/component/dynamic-form/checkbox/checkbox.component';
import { TextAreaComponent } from './common/component/dynamic-form/text-area/text-area.component';
import { FileUploadComponent } from './common/component/dynamic-form/file-upload/file-upload.component';
import { InputComponent } from './common/component/dynamic-form/input/input.component';
import { DynamicFieldDirective } from './common/component/dynamic-form/dynamic-field/dynamic-field.directive';
import { DynamicFormComponent } from './common/component/dynamic-form/dynamic-form/dynamic-form.component';
import { MapViewComponent } from './core/lead-management/map-view/map-view.component';
import { FileSelectDirective, FileUploadModule } from 'ng2-file-upload';
import { PartnerManagementComponent } from './core/partner-management/partner-management/partner-management.component';
import { CreateStageComponent } from './core/cluster-workflow/create-stage/create-stage.component';
import { CreateStageFieldComponent } from './core/cluster-workflow/create-stage-field/create-stage-field.component';
import { CreateStageLinkComponent } from './core/cluster-workflow/create-stage-link/create-stage-link.component';
import { EditStageLinkComponent } from './core/cluster-workflow/edit-stage-link/edit-stage-link.component';
import { DashboardComponent } from './core/lead-management/dashboard/dashboard.component';
import { SignOutComponent } from './common/component/sign-out/sign-out.component';
import { MapComponent } from './common/component/dynamic-form/map/map.component';
import { IotConfigurationComponent } from './core/lead-management/iot-configuration/iot-configuration.component';
import { DepartmentStageAccessComponent } from './core/cluster-workflow/department-stage-access/department-stage-access.component';
import { DeleteModalComponent } from './common/component/delete-modal/delete-modal.component';
import { IotDeviceComponent } from './common/component/iot-device/iot-device.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { DatePipe } from '@angular/common';
import { IotDeviceModalComponent } from './common/component/iot-device-modal/iot-device-modal.component';
import { PartnerLinkComponent } from './core/partner-management/partner-link/partner-link.component';
import { EditPartnerLinkComponent } from './common/component/edit-partner-link/edit-partner-link.component';
import { LeadPerformanceComponent } from './core/performance-reporting/lead-performance/lead-performance.component';
import 'hammerjs';
import { GestureConfig } from 'owl-ng';
import { DateTimeComponent } from './common/component/dynamic-form/date-time/date-time.component';
import { GeoPointComponent } from './common/component/dynamic-form/geo-point/geo-point.component';
import { FloatComponent } from './common/component/dynamic-form/float/float.component';
import { IntegerComponent } from './common/component/dynamic-form/integer/integer.component';
import { ValidationsComponent } from './common/component/validations/validations.component';
import { LeadActivationModalComponent } from './common/component/lead-activation-modal/lead-activation-modal.component';
import { ClusterGenerationComponent } from './core/performance-reporting/cluster-generation/cluster-generation.component';
import { DepartmentOrganisationComponent } from './core/people-management/department-organisation/department-organisation/department-organisation.component';
import { EditDepartmentComponent } from './common/component/edit-department/edit-department/edit-department.component';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { PartnerOrganisationComponent } from './core/partner-management/partner-org/partner-organisation/partner-organisation.component';
import { PropertyGenerationComponent } from './core/performance-reporting/property-generation/property-generation/property-generation.component';
import { LeadReportComponent } from './core/performance-reporting/lead-report/lead-report/lead-report.component';
import { YearSelectorComponent } from './common/component/year-selector/year-selector/year-selector.component';
import { MonthSelectorComponent } from './common/component/month-selector/month-selector/month-selector.component';
import { MatMenuModule } from '@angular/material/menu';
import { AddDepartmentComponent } from './common/component/add-department/add-department.component';
import { ListGroupDirective } from './common/directive/list-select/list-group.directive';
import { ListItemDirective } from './common/directive/list-select/list-item.directive';
import { CustomerAssignmentComponent } from './common/customer-assignment/customer-assignment.component';
import { WorkflowManagementComponent } from './core/cluster-workflow/workflow-management/workflow-management.component';
import { from } from 'rxjs';
import { InspectorComponent } from './core/inspector/inspector.component';

import { ActivityLogModalComponent } from './common/component/activity-log-modal/activity-log-modal.component';
import { CreateActivityLogComponent } from './common/component/create-activity-log/create-activity-log.component';
import { SetReminderComponent } from './common/component/set-reminder/set-reminder.component';
import { ActivityLogListComponent } from './core/lead-management/activity-log-list/activity-log-list.component';
import { LogComponent } from './common/component/log/log.component';
import { MatAutocompleteModule, MatButtonToggleModule } from '@angular/material';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { MessagingService } from './common/service/firebase-messaging/messaging.service';
import { ActivityDashboardComponent } from './core/performance-reporting/activity-dashboard/activity-dashboard.component';
import { TooltipDirective } from './common/directive/tooltip/tooltip.directive';
import { EPCPartnerOrganisationComponent } from './core/partner-management/partner-org/epc-organisation/partner-organisation.component';
import { CreateNoteComponent } from './common/component/create-note/create-note.component';
import { AppDownloadComponent } from './common/component/download-page/app-download/app-download.component';
import { StageAnalyticsComponent } from './core/performance-reporting/stage-analytics/stage-analytics.component';
import { CheckboxNewComponent } from "./common/component/checkbox.component";
import { CheckboxGroupComponent } from "./common/component/checkbox-group.component";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HeaderComponent,
    SidebarComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ListGroupDirective,
    ListItemDirective,
    ResetPasswordComponent,
    LoginComponent,
    LeadNoPropertyComponent,
    LeadListViewComponent,
    AddPropertyComponent,
    UserManagementComponent,
    ClusterManagementComponent,
    ClusterDepartmentManagementComponent,
    ClusterAdminManagementComponent,
    PropertyClusterAssignmentComponent,
    AddClusterComponent,
    AssociateManagementComponent,
    Error4Component,
    AddClusterComponent,
    StageViewComponent,
    StageBarComponent,
    InputComponent,
    ButtonComponent,
    SelectComponent,
    DateComponent,
    RadiobuttonComponent,
    CheckboxComponent,
    TextAreaComponent,
    DynamicFieldDirective,
    DynamicFormComponent,
    PartnerManagementComponent,
    CreateStageComponent,
    CreateStageFieldComponent,
    CreateStageLinkComponent,
    MapViewComponent,
    EditStageLinkComponent,
    DashboardComponent,
    SignOutComponent,
    MapComponent,
    FileUploadComponent,
    IotConfigurationComponent,
    DepartmentStageAccessComponent,
    DeleteModalComponent,
    IotDeviceComponent,
    IotDeviceModalComponent,
    PartnerLinkComponent,
    EditPartnerLinkComponent,
    LeadPerformanceComponent,
    DateTimeComponent,
    GeoPointComponent,
    FloatComponent,
    IntegerComponent,
    ValidationsComponent,
    LeadActivationModalComponent,
    ClusterGenerationComponent,
    DepartmentOrganisationComponent,
    EditDepartmentComponent,
    PartnerOrganisationComponent,
    EPCPartnerOrganisationComponent,
    PropertyGenerationComponent,
    LeadReportComponent,
    YearSelectorComponent,
    MonthSelectorComponent,
    AddDepartmentComponent,
    ListGroupDirective,
    ListItemDirective,
    CustomerAssignmentComponent,
    WorkflowManagementComponent,
    InspectorComponent,
    ActivityLogModalComponent,
    CreateActivityLogComponent,
    SetReminderComponent,
    ActivityLogListComponent,
    LogComponent,
    ActivityDashboardComponent,
    TooltipDirective,
    CreateNoteComponent,
    AppDownloadComponent,
    StageAnalyticsComponent,
    CheckboxGroupComponent, 
    CheckboxNewComponent
  ],
  imports: [
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    GojsAngularModule,
    BrowserModule,
    BrowserAnimationsModule,
    DragulaModule.forRoot(),
    RouterModule.forRoot(AppRoutes),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgxDatatableModule,
    LoadingBarRouterModule,
    NgbModule.forRoot(),
    MatSidenavModule,
    MatTabsModule,
    MatMenuModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatInputModule,
    MatAutocompleteModule,
    FileUploadModule,
    NgxChartsModule,
    MatButtonToggleModule
  ],
  exports: [MatDialogModule],
  bootstrap: [AppComponent],
  providers: [
    DragulaService,
    MessagingService,
    MenuService,
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  entryComponents: [
    ResetPasswordComponent,
    EditStageLinkComponent,
    AddPropertyComponent,
    AddClusterComponent,
    InputComponent,
    ButtonComponent,
    SelectComponent,
    GeoPointComponent,
    DateComponent,
    DateTimeComponent,
    IntegerComponent,
    RadiobuttonComponent,
    CheckboxComponent,
    FloatComponent,
    TextAreaComponent,
    FileUploadComponent,
    DynamicFormComponent,
    SignOutComponent,
    MapComponent,
    DeleteModalComponent,
    IotDeviceModalComponent,
    EditPartnerLinkComponent,
    LeadActivationModalComponent,
    EditDepartmentComponent,
    MonthSelectorComponent,
    YearSelectorComponent,
    AddDepartmentComponent,
    CustomerAssignmentComponent,
    ActivityLogModalComponent,
    CreateActivityLogComponent,
    SetReminderComponent,
    ActivityLogListComponent,
    CreateNoteComponent
  ]
})
export class AppModule {}
