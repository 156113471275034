import { Injectable } from "@angular/core";

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state?: string;
  name?: string;
  type: string;
  icon?: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: "report",
    name: "Performance Reporting",
    type: "sub",
    icon: "../../../../assets/images/partner-management-copy.png",
    roles: ["amplus_business", "cluster_admin", "amplus_admin"],
    children: [
      {
        state: "lead",
        name: "Lead Dashboard",
        roles: ["cluster_admin", "amplus_admin", "amplus_business"],
        icon: "../../../../assets/images/dashboard2.png"
      },
      {
        state: "lead-report",
        name: "Acquisition Dashboard",
        icon: "../../../../assets/images/dashboard2.png",
        roles: ["cluster_admin", "amplus_admin", "amplus_business"]
      },
      {
        state: "stage-analytics",
        name: "Stage Analytics",
        icon: "../../../../assets/images/dashboard2.png",
        roles: ["cluster_admin", "amplus_admin", "amplus_business"]
      },
      {
        state: "cluster-generation",
        name: "Generation Dashboard",
        icon: "../../../../assets/images/dashboard2.png",
        roles: ["cluster_admin", "amplus_admin", "amplus_business"]
      },
      {
        state: "activity-dashboard",
        name: "Activity Dashboard",
        icon: "../../../../assets/images/dashboard2.png",
        roles: ["cluster_admin", "amplus_admin", "amplus_business"]
      }
    ]
  },
  {
    state: "lead",
    name: "Lead Management",
    type: "sub",
    icon: "../../../../assets/images/partner-management-copy.png",
    roles: [
      "amplus_analytics",
      "amplus_business",
      "amplus_technical",
      "amplus_executive",
      "cluster_admin",
      "associate",
      "amplus_finance",
      "amplus_admin",
      "partner_epc",
      "partner_sales",
    ],
    children: [
      {
        state: "list-view",
        name: "Cluster Leads",
        visible: "true",
        icon: "../../../../assets/images/home2.png",
        roles: [
          "amplus_analytics",
          "cluster_admin",
          "associate",
          "amplus_admin",
          "amplus_business",
          "amplus_finance",
          "amplus_technical",
          "partner_epc",
          "partner_sales"
        ]
      },
      {
        state: "property-generation",
        name: "Lead Generation",
        icon: "../../../../assets/images/dashboard2.png",
        roles: [
          "amplus_analytics",
          "cluster_admin",
          "associate",
          "amplus_admin",
          "amplus_business",
          "amplus_finance",
          "amplus_technical",
          "partner_epc",
          "partner_sales"
        ],
        visible: "false"
      },
      // {
      //   state: "property-list",
      //   name: "New Leads",
      //   visible: "true",
      //   icon: "../../../../assets/images/home2.png",
      //   roles: ["amplus_business","amplus_executive",]
      // },
      {
        state: "map-view",
        name: "Map View",
        visible: "false",
        icon: "../../../../assets/images/partner-management-copy.png",
        roles: [
          "amplus_analytics",
          "cluster_admin",
          "associate",
          "amplus_admin",
          "amplus_business",
          "amplus_finance",
          "amplus_technical"
        ]
      },
      {
        state: "stage-view",
        name: "Lead List View",
        visible: "false",
        icon: "../../../../assets/images/partner-management-copy.png",
        roles: [
          "amplus_analytics",
          "cluster_admin",
          "associate",
          "amplus_admin",
          "amplus_business",
          "amplus_finance",
          "amplus_technical",
          "partner_epc",
          "partner_sales"
        ]
      },
      {
        state: "configure-iot",
        name: "Lead IOT Configure",
        visible: "false",
        icon: "../../../../assets/images/partner-management-copy.png",
        roles: ["amplus_technical"]
      },
      // {
      //   state: "no-property",
      //   name: "New Customers",
      //   visible: "true",
      //   icon: "../../../../assets/images/partner-management-copy.png",
      //   roles: ["amplus_business"]
      // },
      {
        state: "active-properties-dashboard",
        name: "Dashboard",
        visible: "false",
        icon: "../../../../assets/images/partner-management-copy.png",
        roles: ["amplus_business", "amplus_analytics", "amplus_technical"]
      }
    ]
  },
  {
    state: "account",
    name: "People Management",
    type: "sub",
    icon: "../../../../assets/images/amplus-management.png",
    roles: ["amplus_admin", "amplus_business", "cluster_admin"],
    children: [
      {
        state: "users",
        name: "Admins",
        visible: "true",
        icon: "../../../../assets/images/amplus-management.png",
        roles: ["amplus_admin"]
      },
      // {
      //   state: "admin",
      //   name: "Cluster Admins",
      //   visible: "true",
      //   icon: "../../../../assets/images/amplus-management.png",
      //   roles: ["amplus_business"]
      // },
      // {
      //   state: "departments",
      //   name: "Department Management",
      //   visible: "true",
      //   roles: ["amplus_business"]
      // },
      // {
      //   state: "dep-org",
      //   name: "Cluster Departments",
      //   visible: "true",
      //   icon: "../../../../assets/images/amplus-management.png",
      //   roles: ["amplus_business", "amplus_admin", "cluster_admin"]
      // },
      {
        state: "partners",
        name: "BD Members",
        roles: ["cluster_admin", "amplus_business", "amplus_admin"],
        icon: "../../../../assets/images/partner-management-copy.png"
      },
      {
        state: "channel-partners",
        name: "Channel Partners",
        roles: ["cluster_admin", "amplus_business", "amplus_admin"],
        icon: "../../../../assets/images/partner-management-copy.png"
      }

    ]
  },
  // {
  //   state: "associate",
  //   name: "Associate Management",
  //   type: "sub",
  //   icon: "../../../../assets/images/associate-management.png",
  //   roles: ["cluster_admin"],
  //   children: [
  //     {
  //       state: "create",
  //       name: "Create Associate",
  //       roles: ["cluster_admin"]
  //     }
  //   ]
  // },
  // {
  //   state: "partner",
  //   name: "Cluster Partners",
  //   type: "sub",
  //   icon: "../../../../assets/images/partner-management-copy.png",
  //   roles: ["cluster_admin"],
  //   children: [
  //     // {
  //     //   state: "create",
  //     //   name: "Create Partner",
  //     //   roles: ["cluster_admin"]
  //     // },
  //     // {
  //     //   state: "link",
  //     //   name: "Partner Linking",
  //     //   visible: "true",
  //     //   roles: ["cluster_admin"]
  //     // },
  //     {
  //       state: "manage",
  //       name: "Cluster Partners",
  //       roles: ["cluster_admin","amplus_business"],
  //       icon: "../../../../assets/images/partner-management-copy.png"
  //     }
  //   ]
  // },
  {
    state: "stage",
    name: "Workflow Management",
    type: "sub",
    icon: "../../../../assets/images/workflow-management.png",
    roles: ["amplus_business"],
    children: [
      {
        state: "create",
        name: "Stages",
        icon: "../../../../assets/images/workflow-management.png",
        roles: ["amplus_business"]
      },
      {
        state: "field",
        name: "Stage Fields",
        icon: "../../../../assets/images/workflow-management.png",
        roles: ["amplus_business"]
      },
      // {
      //   state: "access",
      //   name: "Stage Access",
      //   icon: "../../../../assets/images/workflow-management.png",
      //   roles: ["amplus_business"]
      // },
      {
        state: "link",
        name: "Workflow Path",
        icon: "../../../../assets/images/workflow-management.png",
        roles: ["amplus_business"]
      },
      {
        state: "workflow",
        name: "Cluster Workflow",
        icon: "../../../../assets/images/workflow-management.png",
        roles: ["amplus_business"]
      }
    ]
  }
];

@Injectable()
export class MenuService {
  getAll(): Menu[] {
    return MENUITEMS;
  }
}

// const MENUITEMS = [
//   {
//     type: 'title',
//     name: 'MAIN'
//   },
//   {
//     state: '/',
//     name: 'HOME',
//     type: 'link',
//     icon: 'ion-ios-speedometer'
//   },
//   {
//     state: 'docs',
//     name: 'DOCS',
//     type: 'link',
//     icon: 'ion-ios-help'
//   },
//   {
//     type: 'divider'
//   },
//   {
//     type: 'title',
//     name: 'APPS'
//   },
//   {
//     state: 'email',
//     name: 'EMAIL',
//     type: 'link',
//     icon: 'ion-ios-email',
//     badge: [
//       {
//         type: 'warning',
//         value: 'NEW'
//       }
//     ]
//   },
//   {
//     state: 'social',
//     name: 'SOCIAL',
//     type: 'link',
//     icon: 'ion-ios-person'
//   },
//   {
//     state: 'calendar',
//     name: 'CALENDAR',
//     type: 'link',
//     icon: 'ion-ios-calendar'
//   },
//   {
//     state: 'taskboard',
//     name: 'TASKBOARD',
//     type: 'link',
//     icon: 'ion-ios-paper'
//   },
//   {
//     type: 'divider'
//   },
//   {
//     type: 'title',
//     name: 'UI'
//   },
//   {
//     state: 'components',
//     name: 'COMPONENTS',
//     type: 'sub',
//     icon: 'ion-ios-albums',
//     badge: [
//       {
//         type: 'success',
//         value: '5'
//       }
//     ],
//     children: [
//       {
//         state: 'buttons',
//         name: 'BUTTONS'
//       },
//       {
//         state: 'accordion',
//         name: 'ACCORDION'
//       },
//       {
//         state: 'alert',
//         name: 'ALERT'
//       },
//       {
//         state: 'carousel',
//         name: 'CAROUSEL'
//       },
//       {
//         state: 'collapse',
//         name: 'COLLAPSE'
//       },
//       {
//         state: 'datepicker',
//         name: 'DATEPICKER'
//       },
//       {
//         state: 'dropdown',
//         name: 'DROPDOWN'
//       },
//       {
//         state: 'modal',
//         name: 'MODAL'
//       },
//       {
//         state: 'pagination',
//         name: 'PAGINATION'
//       },
//       {
//         state: 'popover',
//         name: 'POPOVER'
//       },
//       {
//         state: 'progress',
//         name: 'PROGRESS'
//       },
//       {
//         state: 'rating',
//         name: 'RATING'
//       },
//       {
//         state: 'tabs',
//         name: 'TABS'
//       },
//       {
//         state: 'timepicker',
//         name: 'TIMEPICKER'
//       },
//       {
//         state: 'tooltip',
//         name: 'TOOLTIP'
//       },
//       {
//         state: 'typeahead',
//         name: 'TYPEAHEAD'
//       }
//     ]
//   },
//   {
//     state: 'cards',
//     name: 'CARDS',
//     type: 'sub',
//     icon: 'ion-ios-photos',
//     children: [
//       {
//         state: 'basic',
//         name: 'BASIC'
//       },
//       {
//         state: 'draggable',
//         name: 'DRAGGABLE'
//       }
//     ]
//   },
//   {
//     state: 'forms',
//     name: 'FORMS',
//     type: 'sub',
//     icon: 'ion-ios-box',
//     children: [
//       {
//         state: 'basic',
//         name: 'BASIC'
//       },
//       {
//         state: 'masks',
//         name: 'MASKS'
//       },
//       {
//         state: 'validation',
//         name: 'VALIDATION'
//       },
//       {
//         state: 'upload',
//         name: 'UPLOAD'
//       }
//     ]
//   },
//   {
//     state: 'tables',
//     name: 'TABLES',
//     type: 'sub',
//     icon: 'ion-ios-grid-view',
//     badge: [
//       {
//         type: 'primary',
//         value: '2'
//       }
//     ],
//     children: [
//       {
//         state: 'basic',
//         name: 'BASIC'
//       },
//       {
//         state: 'responsive',
//         name: 'RESPONSIVE'
//       }
//     ]
//   },
//   {
//     type: 'divider'
//   },
//   {
//     type: 'title',
//     name: 'DATAVIZ'
//   },
//   {
//     state: 'datatable',
//     name: 'DATATABLES',
//     type: 'sub',
//     icon: 'ion-ios-list',
//     children: [
//       {
//         state: 'fullscreen',
//         name: 'FULLSCREEN'
//       },
//       {
//         state: 'editing',
//         name: 'EDITING'
//       },
//       {
//         state: 'filter',
//         name: 'FILTER'
//       },
//       {
//         state: 'paging',
//         name: 'PAGING'
//       },
//       {
//         state: 'pinning',
//         name: 'PINNING'
//       },
//       {
//         state: 'selection',
//         name: 'SELECTION'
//       },
//       {
//         state: 'sorting',
//         name: 'SORTING'
//       }
//     ]
//   },
//   {
//     state: 'charts',
//     name: 'CHARTS',
//     type: 'sub',
//     icon: 'ion-ios-analytics',
//     badge: [
//       {
//         type: 'danger',
//         value: 'HOT'
//       }
//     ],
//     children: [
//       {
//         state: 'bar',
//         name: 'BAR'
//       },
//       {
//         state: 'pie',
//         name: 'PIE'
//       },
//       {
//         state: 'line',
//         name: 'LINE'
//       },
//       {
//         state: 'misc',
//         name: 'MISC'
//       }
//     ]
//   },
//   {
//     state: 'maps',
//     name: 'MAPS',
//     type: 'sub',
//     icon: 'ion-ios-navigate',
//     children: [
//       {
//         state: 'google',
//         name: 'GOOGLE'
//       },
//       {
//         state: 'fullscreen',
//         name: 'FULLSCREEN'
//       }
//     ]
//   },
//   {
//     type: 'divider'
//   },
//   {
//     type: 'title',
//     name: 'EXTRAS'
//   },
//   {
//     state: 'account',
//     name: 'ACCOUNT',
//     type: 'sub',
//     icon: 'ion-ios-people',
//     children: [
//       {
//         state: 'signin',
//         name: 'SIGNIN'
//       },
//       {
//         state: 'signup',
//         name: 'SIGNUP'
//       },
//       {
//         state: 'forgot',
//         name: 'FORGOT'
//       },
//       {
//         state: 'lockscreen',
//         name: 'LOCKSCREEN'
//       }
//     ]
//   },
//   {
//     state: 'pages',
//     name: 'PAGES',
//     type: 'sub',
//     icon: 'ion-ios-folder',
//     children: [
//       {
//         state: 'forum',
//         name: 'FORUM'
//       },
//       {
//         state: 'timeline',
//         name: 'TIMELINE'
//       },
//       {
//         state: 'activity',
//         name: 'ACTIVITY'
//       },
//       {
//         state: 'pricing',
//         name: 'PRICING'
//       },
//       {
//         state: 'blank',
//         name: 'BLANK'
//       }
//     ]
//   },
//   {
//     state: 'error',
//     name: 'ERROR',
//     type: 'sub',
//     icon: 'ion-ios-information',
//     children: [
//       {
//         state: '404',
//         name: '404'
//       },
//       {
//         state: '500',
//         name: '500'
//       }
//     ]
//   },
//   {
//     state: 'media',
//     name: 'MEDIA',
//     type: 'sub',
//     icon: 'ion-ios-film',
//     children: [
//       {
//         state: 'grid',
//         name: 'GRID'
//       },
//       {
//         state: 'tile',
//         name: 'TILE'
//       }
//     ]
//   }
// ];
