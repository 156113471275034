import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'src/app/common/service/loader/loader.service';
import { $ as abc} from 'protractor';
import { MenuToggleService } from 'src/app/common/service/menu-toggle/menu-toggle.service';
import { MessagingService } from 'src/app/common/service/firebase-messaging/messaging.service';
declare var $: any;

const SMALL_WIDTH_BREAKPOINT = 991;

export interface Options {
  heading?: string;
  removeFooter?: boolean;
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  private _router: Subscription;
  private mediaMatcher: MediaQueryList = matchMedia(
    `(max-width: abc{SMALL_WIDTH_BREAKPOINT}px)`
  );

  routeOptions: Options;

  options = {
    lang: 'en',
    dark: false,
    settings: false,
    docked: false,
    boxed: false,
    opened: false
  };

  currentLang = 'en';
  heading: string;

  @ViewChild('sidebar', { static: false })
  sidebar;
  @ViewChild('sidemenu', { static: true })
  sidemenu;

  constructor(
    private _element: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private titleService: Title,
    private loaderService: LoaderService,
    private menuToggle: MenuToggleService,
    private messagingService: MessagingService,
    zone: NgZone
  ) {
    this.mediaMatcher.addListener(mql =>
      zone.run(() => this.mediaMatcher = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`))
      );
    
  }

  ngOnInit(): void {
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();

    this._router = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // Scroll to top on view load
        document.querySelector('.main-content').scrollTop = 0;
        if (this.isOver()) {
          this.sidemenu.close();
        }
        this.runOnRouteChange();
      });

    this.runOnRouteChange();
    this.loaderService.loaderState.subscribe(val => {
      if(val == true) {
        $('.loader-animation').addClass("lds-ellipsis");
        $('.overlay-container').addClass('overlay');
      } 
      else {
        $('.loader-animation').removeClass('lds-ellipsis');
        $('.overlay-container').removeClass('overlay');
      }
    });

    this.menuToggle.menuToggle.subscribe(val => {
      if(!val) {
        this.sidemenu.close();
      }
      else {
        this.sidemenu.open();
      }
    });
  }

  ngOnDestroy() {
    this._router.unsubscribe();
  }

  runOnRouteChange(): void {
    this.route.children.forEach((route: ActivatedRoute) => {
      let activeRoute: ActivatedRoute = route;
      while (activeRoute.firstChild) {
        activeRoute = activeRoute.firstChild;
      }
      this.routeOptions = activeRoute.snapshot.data;
    });

    if (this.routeOptions && this.routeOptions.hasOwnProperty('heading')) {
      this.heading = this.routeOptions.heading;
    }
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle(
      'Stacked - Bootstrap 4 Angular Admin Template | ' + newTitle
    );
  }

  isOver(): boolean {
    return this.mediaMatcher.matches;
  }

  toggleFullscreen(): void {
    const elem = this._element.nativeElement.querySelector('.main-content');
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullScreen) {
      elem.webkitRequestFullScreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.msRequestFullScreen) {
      elem.msRequestFullScreen();
    }
  }
}
